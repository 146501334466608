import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PermissionsConfig from './PermissionsConfig';
import NotificationsConfig from './NotificationsConfig';
import EventCalendar from './EventCalendar';
import EventManagement from './EventManagement';
import NotificationHistory from './NotificationHistory';

const ServerConfiguration = () => {
  const [activeTab, setActiveTab] = useState('permissions');
  const [serverDetails, setServerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { serverId } = useParams();

  useEffect(() => {
    const fetchServerDetails = async () => {
      if (!serverId) {
        setError('Server ID is missing');
        setLoading(false);
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/details`, { withCredentials: true });
        setServerDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching server details:', error);
        setError('Failed to fetch server details');
        setLoading(false);
      }
    };
    fetchServerDetails();
  }, [serverId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!serverDetails) return <div>No server details available</div>;

  return (
    <div className="server-configuration">
      <h2>Server Configuration</h2>
      {serverDetails && <h3>{serverDetails.name}</h3>}
      
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'permissions' ? 'active' : ''}`}
            onClick={() => setActiveTab('permissions')}
          >
            Permissions
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'notifications' ? 'active' : ''}`}
            onClick={() => setActiveTab('notifications')}
          >
            Notifications
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'calendar' ? 'active' : ''}`}
            onClick={() => setActiveTab('calendar')}
          >
            Calendar
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'events' ? 'active' : ''}`}
            onClick={() => setActiveTab('events')}
          >
            Event Management
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'notificationHistory' ? 'active' : ''}`}
            onClick={() => setActiveTab('notificationHistory')}
          >
            Notification History
          </button>
        </li>
      </ul>

      <div className="tab-content mt-3">
        {activeTab === 'permissions' && serverId && <PermissionsConfig serverId={serverId} />}
        {activeTab === 'notifications' && serverId && <NotificationsConfig serverId={serverId} />}
        {activeTab === 'calendar' && serverId && <EventCalendar serverId={serverId} />}
        {activeTab === 'events' && serverId && <EventManagement serverId={serverId} />}
        {activeTab === 'notificationHistory' && serverId && <NotificationHistory serverId={serverId} />}
      </div>
    </div>
  );
};

export default ServerConfiguration;