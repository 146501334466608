import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const AddRoKAccount = () => {
  const { t } = useTranslation(['rok1', 'translation']);
  const [rid, setRid] = useState('');
  const [image, setImage] = useState(null);
  const [hasPermission, setHasPermission] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { serverId } = useParams();

  useEffect(() => {
    const checkPermission = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/check-permission`, { withCredentials: true });
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setHasPermission(false);
        }
        setLoading(false);
      }
    };

    checkPermission();
  }, [serverId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    const formData = new FormData();
    formData.append('rid', rid);
    if (image) {
      formData.append('verificationImage', image);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/server/${serverId}/rok-accounts`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Account added successfully:', response.data);
      setSuccess(true);
      setRid('');
      setImage(null);
    } catch (error) {
      console.error('Error adding account:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError(t('rok1:account.addError'));
      }
    }
  };

  if (!hasPermission) {
    return <Navigate to="/dashboard" replace />;
  }

  if (loading) return <div>{t('translation:loading')}</div>;

  return (
    <div>
      <h2>{t('rok1:account.addTitle')}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{t('rok1:account.addSuccess')}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="rid">{t('rok1:account.id')}</label>
          <input
            type="text"
            id="rid"
            className="form-control"
            value={rid}
            onChange={(e) => setRid(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">{t('rok1:account.verificationImage')}</label>
          <input
            type="file"
            id="image"
            className="form-control-file"
            onChange={(e) => setImage(e.target.files[0])}
            accept="image/*"
          />
        </div>
        <button type="submit" className="btn btn-primary">{t('rok1:account.add')}</button>
      </form>
    </div>
  );
};

export default AddRoKAccount;