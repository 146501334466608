import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function PromoCodeManagement({ user }) {
  const [promoCodes, setPromoCodes] = useState([]);
  const [newCode, setNewCode] = useState({ code: '', discountPercent: 0, validFrom: '', validTo: '', maxUses: 0 });
  const [editingCode, setEditingCode] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.role !== 'owner') {
      navigate('/dashboard');
    } else {
      fetchPromoCodes();
    }
  }, [user, navigate]);

  const fetchPromoCodes = async () => {
    try {
      setLoading(true);
      setError('');
      console.log('Fetching promo codes...');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/promo-codes`, { 
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Response:', response);
      if (Array.isArray(response.data)) {
        setPromoCodes(response.data);
      } else {
        console.error('Received non-array data:', response.data);
        setError('Received invalid data from server');
        setPromoCodes([]);
      }
    } catch (err) {
      console.error('Error fetching promo codes:', err.response || err);
      setError('Failed to fetch promo codes. Please try again later.');
      setPromoCodes([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingCode) {
      setEditingCode({ ...editingCode, [name]: value });
    } else {
      setNewCode({ ...newCode, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    try {
      if (editingCode) {
        await axios.put(`${process.env.REACT_APP_API_URL}/subscription/promo-codes/${editingCode.id}`, editingCode, { withCredentials: true });
        setSuccessMessage('Promo code updated successfully');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/subscription/promo-codes`, newCode, { withCredentials: true });
        setSuccessMessage('New promo code added successfully');
      }
      setEditingCode(null);
      setNewCode({ code: '', discountPercent: 0, validFrom: '', validTo: '', maxUses: 0 });
      fetchPromoCodes();
    } catch (err) {
      console.error('Error saving promo code:', err.response || err);
      setError('Failed to save promo code. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/subscription/promo-codes/${id}`, { withCredentials: true });
      setSuccessMessage('Promo code deleted successfully');
      fetchPromoCodes();
    } catch (err) {
      console.error('Error deleting promo code:', err.response || err);
      setError('Failed to delete promo code. Please try again.');
    }
  };

  if (!user || user.role !== 'owner') {
    return null;
  }

  if (loading) {
    return <div className="text-center mt-5"><i className="fas fa-spinner fa-spin fa-3x"></i></div>;
  }

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Promo Code Management</h1>
      <p className="mb-4">Manage your promotional codes here.</p>

      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">{editingCode ? 'Edit' : 'Add'} Promo Code</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Code</label>
                <input type="text" className="form-control" name="code" value={editingCode ? editingCode.code : newCode.code} onChange={handleInputChange} required />
              </div>
              <div className="form-group col-md-6">
                <label>Discount Percent</label>
                <input type="number" className="form-control" name="discountPercent" value={editingCode ? editingCode.discount_percent : newCode.discountPercent} onChange={handleInputChange} required min="0" max="100" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>Valid From</label>
                <input type="date" className="form-control" name="validFrom" value={editingCode ? editingCode.valid_from.split('T')[0] : newCode.validFrom} onChange={handleInputChange} required />
              </div>
              <div className="form-group col-md-4">
                <label>Valid To</label>
                <input type="date" className="form-control" name="validTo" value={editingCode ? editingCode.valid_to.split('T')[0] : newCode.validTo} onChange={handleInputChange} required />
              </div>
              <div className="form-group col-md-4">
                <label>Max Uses</label>
                <input type="number" className="form-control" name="maxUses" value={editingCode ? editingCode.max_uses : newCode.maxUses} onChange={handleInputChange} required min="0" />
              </div>
            </div>
            <button type="submit" className="btn btn-primary">{editingCode ? 'Update' : 'Add'} Promo Code</button>
            {editingCode && (
              <button type="button" className="btn btn-secondary ml-2" onClick={() => setEditingCode(null)}>Cancel Edit</button>
            )}
          </form>
        </div>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Promo Codes List</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Discount %</th>
                  <th>Valid From</th>
                  <th>Valid To</th>
                  <th>Max Uses</th>
                  <th>Current Uses</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {promoCodes.length > 0 ? (
                  promoCodes.map(code => (
                    <tr key={code.id}>
                      <td>{code.code}</td>
                      <td>{code.discount_percent}</td>
                      <td>{new Date(code.valid_from).toLocaleDateString()}</td>
                      <td>{new Date(code.valid_to).toLocaleDateString()}</td>
                      <td>{code.max_uses}</td>
                      <td>{code.current_uses}</td>
                      <td>
                        <button className="btn btn-info btn-sm mr-2" onClick={() => setEditingCode(code)}>Edit</button>
                        <button className="btn btn-danger btn-sm" onClick={() => handleDelete(code.id)}>Delete</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">No promo codes found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoCodeManagement;