import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useServerContext } from '../contexts/ServerContext';
import { useTranslation } from 'react-i18next';
import AuthButton from './AuthButton';
import ServerSwitcher from './ServerSwitcher';
import Menu from './Menu';

const Layout = ({ children, user }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { selectedServer } = useServerContext();
  const [isSubscriptionOwner, setIsSubscriptionOwner] = useState(false);
  const [userPermissions, setUserPermissions] = useState({});
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false);

  const isHomePage = location.pathname === '/';
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsLanguageDropdownVisible(false);
  };
const checkUserPermissions = useCallback(async () => {
  if (user && selectedServer) {
    try {
      console.log('Checking permissions for server:', selectedServer);
      
      const permissionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/server/${selectedServer}/permissions-me`, { withCredentials: true });
      console.log('Permissions response:', permissionsResponse.data);
      
      setIsSubscriptionOwner(permissionsResponse.data.isOwner);
      setUserPermissions(permissionsResponse.data.permissions);
    } catch (error) {
      console.error('Error checking user permissions:', error);
    }
  }
}, [user, selectedServer]);


useEffect(() => {
  checkUserPermissions();
  console.log('User data in Layout:', user);
  console.log('Selected server:', selectedServer);
  // Reinitialize tooltips and dropdowns
  if (window.$ && typeof window.$.fn.tooltip === 'function' && typeof window.$.fn.dropdown === 'function') {
    window.$('[data-toggle="tooltip"]').tooltip();
    window.$('.dropdown-toggle').dropdown();
  }
}, [user, selectedServer, checkUserPermissions]);



const getAvatarUrl = (user) => {
  if (user && user.local_avatar_path) {
    return `${process.env.REACT_APP_API_URL}${user.local_avatar_path}`;
  }
  return 'https://via.placeholder.com/150'; // Placeholder, je�li nie ma avatara
};
  return (

    <div id="wrapper">
      {/* Sidebar */}
      {!isHomePage && (
        <Menu 
          user={user} 
          selectedServer={selectedServer} 
          isSubscriptionOwner={isSubscriptionOwner} 
          userPermissions={userPermissions} 
        />
      )}
      {/* Content Wrapper */}
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          {/* Topbar */}
          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
              <i className="fa fa-bars"></i>
            </button>

            <ul className="navbar-nav ml-auto">
              {user && <ServerSwitcher />}

              <li className="nav-item dropdown no-arrow mx-1">
                <a className="nav-link dropdown-toggle" href="#!" id="languageDropdown" role="button"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                   onClick={(e) => e.preventDefault()}>
                  <i className="fas fa-globe fa-fw"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                     aria-labelledby="languageDropdown">
                  <a className="dropdown-item" href="#!" onClick={(e) => { e.preventDefault(); changeLanguage('en'); }}>
                    <i className="fas fa-flag-usa fa-sm fa-fw mr-2 text-gray-400"></i>
                    English
                  </a>
                  <a className="dropdown-item" href="#!" onClick={(e) => { e.preventDefault(); changeLanguage('pl'); }}>
                    <i className="fas fa-flag fa-sm fa-fw mr-2 text-gray-400"></i>
                    Polski
                  </a>
                </div>
              </li>
              <div className="topbar-divider d-none d-sm-block"></div>

              {/* User Information */}
      {user ? (
        <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#!" id="userDropdown" role="button"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
             onClick={(e) => e.preventDefault()}>
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{user.username}</span>
            <img 
  className="img-profile rounded-circle"
  src={getAvatarUrl(user)}
  alt="User Avatar"
  onError={(e) => {
    console.error('Error loading avatar:', e);
    e.target.onerror = null; // Zapobiegamy niesko�czonej p�tli
    e.target.src='https://via.placeholder.com/150';
  }}
/>
          </a>
                  <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                       aria-labelledby="userDropdown">
                    <Link className="dropdown-item" to="/profile">
                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      {t('profile')}
                    </Link>
                    <div className="dropdown-divider"></div>
                    <AuthButton user={user} />
                  </div>
                </li>
              ) : (
                <li className="nav-item">
                  <Link className="nav-link" to="/login">{t('login')}</Link>
                </li>
              )}
            </ul>
          </nav>

          {/* Main Content */}
          <div className="container-fluid">
            {children}
          </div>
        </div>

        {/* Footer */}
        <footer className="sticky-footer bg-white">
          <div className="container my-auto">
            <div className="copyright text-center my-auto">
              <span>Copyright � ROK TOOL 2024</span>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Layout;