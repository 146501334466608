import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import KvKStats from './KvKStats';
import KvKModal from './KvKModal';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';

const KvKManagement = () => {
  const [kvkEvents, setKvKEvents] = useState([]);
  const [kvkDetails, setKvKDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [kvkToEdit, setKvkToEdit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedKvK, setSelectedKvK] = useState(null);
  const { serverId } = useParams();

  const fetchKvKEvents = useCallback(async () => {
    try {
      setLoading(true);
      console.log('Fetching KvK events for server:', serverId);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/server/${serverId}`, { withCredentials: true });
      console.log('KvK events response:', response.data);
      setKvKEvents(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching KvK events:', err);
      setError('Failed to fetch KvK events: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  }, [serverId]);

  useEffect(() => {
    fetchKvKEvents();
  }, [fetchKvKEvents]);

  const fetchKvKDetails = useCallback(async (kvkId) => {
    try {
      console.log('Fetching KvK details for:', kvkId, 'serverId:', serverId);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/${kvkId}`, {
        params: { serverId },
        withCredentials: true
      });
      console.log('KvK details response:', response.data);
      setKvKDetails(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching KvK details:', error.response?.data || error.message);
      setError('Failed to fetch KvK details: ' + (error.response?.data?.error || error.message));
    }
  }, [serverId]);

  const handleSubmit = async (eventData) => {
    try {
      const dataToSend = {
        ...eventData,
        serverId: serverId
      };

      let response;
      if (kvkToEdit) {
        // Aktualizacja istniej�cego KvK
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/kvk/${kvkToEdit.id}`, 
          dataToSend, 
          { withCredentials: true }
        );
      } else {
        // Tworzenie nowego KvK
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/kvk`, 
          dataToSend, 
          { withCredentials: true }
        );
      }
      
      setKvKEvents(prevEvents => 
        kvkToEdit
          ? prevEvents.map(event => event.id === kvkToEdit.id ? response.data : event)
          : [...prevEvents, response.data]
      );
      setShowModal(false);
      setKvkToEdit(null);
      setError(null);
      fetchKvKEvents(); // Od�wie� list� KvK
    } catch (err) {
      console.error('Failed to submit KvK event:', err);
      setError('Failed to submit KvK event: ' + (err.response?.data?.error || err.message));
    }
  };
const handleEditKvK = async (kvkId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/${kvkId}`, {
      params: { serverId },
      withCredentials: true
    });
    console.log('KvK data from API:', response.data);
    // Parsujemy dkp_multipliers, je�li to string JSON
    const kvkData = {
      ...response.data,
      dkp_multipliers: response.data.dkp_multipliers 
        ? (typeof response.data.dkp_multipliers === 'string' 
            ? JSON.parse(response.data.dkp_multipliers) 
            : response.data.dkp_multipliers)
        : null
    };
    setKvkToEdit(kvkData);
    setShowModal(true);
  } catch (error) {
    console.error('Error fetching KvK details:', error);
    setError('Failed to fetch KvK details for editing');
  }
};

const handleOpenModal = (kvk = null) => {
  setKvkToEdit(kvk);
  setShowModal(true);
};
  const handleEndKvK = async (eventId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/kvk/${eventId}/end`,
        { serverId },
        { withCredentials: true }
      );
      setKvKEvents(prevEvents => prevEvents.map(event => event.id === eventId ? response.data : event));
      setError(null);
      fetchKvKEvents(); // Od�wie� list� po zako�czeniu KvK
    } catch (err) {
      console.error('Failed to end KvK event:', err);
      setError('Failed to end KvK event: ' + (err.response?.data?.error || err.message));
    }
  };

  const handleSelectKvK = (kvkId) => {
    setSelectedKvK(kvkId);
    fetchKvKDetails(kvkId);
  };
const handleCalculateStats = async (kvkId) => {
  try {
    console.log(`Calculating stats for KvK ID: ${kvkId}, Server ID: ${serverId}`);
    const forceCalculation = window.confirm("Do you want to force recalculation of stats? This may take a while.");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/kvk/${kvkId}/calculate`, 
      { forceCalculation },  // Dodajemy opcj� wymuszenia przeliczenia
      { 
        params: { serverId },
        withCredentials: true
      }
    );
    console.log('Calculation response:', response.data);
    if (response.data.success) {
      alert('Stats calculated successfully!');
    } else {
      alert(response.data.message || 'Stats calculation completed with warnings.');
    }
  } catch (error) {
    console.error('Error calculating KvK stats:', error);
    alert('Error calculating stats: ' + (error.response?.data?.error || error.message));
  }
};
  const handleCloseModal = () => {
    setKvkToEdit(null);
    setShowModal(false);
  };

  if (loading) return <div>Loading KvK events...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container className="kvk-management">
      <h2 className="mb-4">KvK Management</h2>
      <Button onClick={() => handleOpenModal()} className="mb-4">Create New KvK</Button>
      
      <Row>
        {kvkEvents.map(event => (
          <Col md={4} key={event.id} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{event.name}</Card.Title>
                <Card.Text>
                  Status: {event.status}<br/>
                  Pre-KvK Date: {new Date(event.pre_kvk_date).toLocaleDateString()}<br/>
                  Start Date: {new Date(event.start_date).toLocaleDateString()}<br/>
                  {event.end_date && `End Date: ${new Date(event.end_date).toLocaleDateString()}`}
                </Card.Text>
                <Button variant="primary" onClick={() => handleEditKvK(event.id)} className="mr-2">Edit</Button>
                {event.status !== 'completed' && (
                  <Button variant="warning" onClick={() => handleEndKvK(event.id)} className="mr-2">End KvK</Button>
                )}
                <Button variant="info" onClick={() => handleSelectKvK(event.id)}>View Details</Button>
                <Button variant="success" onClick={() => handleCalculateStats(event.id)}>Calculate Stats</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {selectedKvK && kvkDetails && (
        <div className="mt-4">
          <h3>KvK Details: {kvkDetails.name}</h3>
          <KvKStats kvkId={selectedKvK} serverId={serverId} />
        </div>
      )}

      <KvKModal
        show={showModal}
        handleClose={handleCloseModal}
        kvkToEdit={kvkToEdit}
        serverId={serverId}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default KvKManagement;