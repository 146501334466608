import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Form, Container, ButtonGroup, Button } from 'react-bootstrap';
import KvKStatBlocks from './modules/kvk/KvKStatBlocks';
import KvKPowerDeathsChart from './modules/kvk/KvKPowerDeathsChart';
import KvKKillsChart from './modules/kvk/KvKKillsChart';
import KvKProgressWidget from './modules/kvk/KvKProgressWidget'; 
import KvKParticipantsTable from './modules/kvk/KvKParticipantsTable'; // Dodaj import nowego komponentu


const KvKOverallStats = ({ kvkId, startDate, endDate }) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('fromStart');
  const [selectedRange, setSelectedRange] = useState('overall');
  const [powerRanges, setPowerRanges] = useState([]);
  const [canViewDiscordLinks, setCanViewDiscordLinks] = useState(false);
  const [requirementsType, setRequirementsType] = useState('kills_deaths');


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serverId = searchParams.get('serverId');

useEffect(() => {
  const fetchData = async () => {
    if (!serverId) {
      setError('Server ID is missing');
      setLoading(false);
      return;
    }
    try {
      setLoading(true);

      // Pobierz uprawnienia u�ytkownika
      const permissionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/permissions-me`, {
        withCredentials: true
      });
      console.log('Permissions response:', permissionsResponse.data);
      
      const newCanViewDiscordLinks = permissionsResponse.data.isOwner || permissionsResponse.data.permissions.canViewDiscordLinks;
      console.log('Can view Discord links:', newCanViewDiscordLinks);
      setCanViewDiscordLinks(newCanViewDiscordLinks);
      
      // Pobierz szczeg�y KvK
      const kvkResponse = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/${kvkId}`, {
        params: { serverId },
        withCredentials: true
      });

      // Ustawienie zakres�w mocy na podstawie power_requirements
      const ranges = [
        { id: 'overall', min_power: 0, max_power: Infinity },
        ...kvkResponse.data.power_requirements.map(req => ({
          id: req.id.toString(),
          min_power: parseInt(req.min_power),
          max_power: parseInt(req.max_power)
        }))
      ];
      setPowerRanges(ranges);

      // Dodaj pobranie typu wymaga� z odpowiedzi API
      setRequirementsType(kvkResponse.data.requirements_type || 'kills_deaths');

      // Pobierz statystyki KvK
      const statsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/${kvkId}/stats`, {
        params: { startDate, endDate, serverId },
        withCredentials: true
      });
      console.log('Fetched stats data:', statsResponse.data);

      if (Array.isArray(statsResponse.data)) {
        setStats(statsResponse.data);
      } else {
        throw new Error('Unexpected stats data format');
      }

      setLoading(false);
    } catch (err) {
      console.error('Error fetching KvK data:', err);
      setError('Failed to fetch KvK data: ' + err.message);
      setLoading(false);
    }
  };
  fetchData();
}, [kvkId, startDate, endDate, serverId]);


  const getStatsForRange = (range) => {
    if (!Array.isArray(stats)) {
      console.warn('Stats is not an array:', stats);
      return [];
    }
    if (range === 'overall') {
      return stats;
    }
    return stats.filter(stat => stat.power_range_id.toString() === range);
  };

  const formatPowerRange = (range) => {
    if (range === 'overall') return 'Overall';
    const selectedRange = powerRanges.find(r => r.id === range);
    if (!selectedRange) return '';
    const formatMillions = (value) => {
      const millions = Math.floor(value / 1000000);
      return `${millions}M`;
    };
    return `${formatMillions(selectedRange.min_power)} - ${selectedRange.max_power === Infinity ? '?' : formatMillions(selectedRange.max_power)}`;
  };

  if (loading) return <div>Loading overall stats...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container fluid>
      <ButtonGroup className="mb-3">
        {powerRanges.map((range) => (
          <Button
            key={range.id}
            variant={selectedRange === range.id ? 'primary' : 'secondary'}
            onClick={() => setSelectedRange(range.id)}
          >
            {formatPowerRange(range.id)}
          </Button>
        ))}
      </ButtonGroup>
      <Form.Group className="mb-3">
        <Form.Label>View Mode:</Form.Label>
        <Form.Control as="select" value={viewMode} onChange={(e) => setViewMode(e.target.value)}>
          <option value="fromStart">From Start</option>
          <option value="dailyChange">Daily Change</option>
        </Form.Control>
      </Form.Group>
      <KvKStatBlocks stats={getStatsForRange(selectedRange)} selectedRange={selectedRange} />
    {stats.length > 0 && powerRanges.length > 0 && (
      <KvKProgressWidget 
        stats={stats} 
        powerRanges={powerRanges}
        requirementsType={requirementsType}
        selectedRange={selectedRange}
      />
    )}

      <KvKPowerDeathsChart 
        stats={getStatsForRange(selectedRange)} 
        viewMode={viewMode} 
        selectedRange={selectedRange}
        powerRanges={powerRanges}
      />
      <KvKKillsChart 
        stats={getStatsForRange(selectedRange)} 
        viewMode={viewMode} 
        selectedRange={selectedRange}
        powerRanges={powerRanges}
      />
      <h3 className="mt-5 mb-3">KvK Participants</h3>
      <KvKParticipantsTable 
        stats={stats}
        powerRanges={powerRanges}
        requirementsType={requirementsType}
        canViewDiscordLinks={canViewDiscordLinks}
      />
    </Container>
  );
};

export default KvKOverallStats;