import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useServerContext } from '../contexts/ServerContext';

const ServerSwitcher = () => {
  const [servers, setServers] = useState([]);
  const { selectedServer, setSelectedServer } = useServerContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchServers();
  }, []);

const fetchServers = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/subscribed-servers`, { withCredentials: true });
    console.log('Server response:', response.data);
    if (Array.isArray(response.data)) {
      setServers(response.data);
      if (response.data.length > 0 && !selectedServer) {
        setSelectedServer(response.data[0].server_id);
      }
    } else {
      console.error('Received non-array data:', response.data);
      setError('Received invalid data from server');
    }
  } catch (error) {
    console.error('Error fetching subscribed servers:', error);
    setError(error.response?.data?.error || 'Failed to fetch servers');
  }
};
  const handleServerChange = (e) => {
    const newServer = e.target.value;
    setSelectedServer(newServer);
  };

  if (error) {
    return <div className="text-danger">Error: {error}</div>;
  }

  if (servers.length === 0) {
    return <div className="text-muted">No servers available.</div>;
  }

  return (
    <li className="nav-item dropdown no-arrow mx-1">
      <a className="nav-link dropdown-toggle" href="#" id="serverDropdown" role="button"
         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="fas fa-server fa-fw"></i>
      </a>
      <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
           aria-labelledby="serverDropdown">
        {servers.map((server) => (
          <a key={server.server_id} 
             className={`dropdown-item ${selectedServer === server.server_id ? 'active' : ''}`}
             href="#" 
             onClick={() => handleServerChange({ target: { value: server.server_id } })}>
            {server.server_name}
          </a>
        ))}
      </div>
    </li>
  );
};

export default ServerSwitcher;