import React, { useMemo } from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const formatNumber = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
};

const RequirementsDisplay = ({ requirements, requirementsType, achieved }) => (
  <Card className="mb-4 shadow-sm">
    <Card.Body>
      <Row>
        {requirementsType === 'kills_deaths' && (
          <>
            <Col>
              <h6 className="text-muted mb-0">Required Kills (T4+T5)</h6>
              <p className="font-weight-bold h4">{formatNumber(requirements.kills)}</p>
              <p className="text-muted">Achieved: {formatNumber(achieved.kills)}</p>
            </Col>
            <Col>
              <h6 className="text-muted mb-0">Required Deaths</h6>
              <p className="font-weight-bold h4">{formatNumber(requirements.deaths)}</p>
              <p className="text-muted">Achieved: {formatNumber(achieved.deaths)}</p>
            </Col>
          </>
        )}
        {(requirementsType === 'dkp' || requirementsType === 'dkp_deaths') && (
          <Col>
            <h6 className="text-muted mb-0">Required DKP</h6>
            <p className="font-weight-bold h4">{formatNumber(requirements.dkp)}</p>
            <p className="text-muted">Achieved: {formatNumber(achieved.dkp)}</p>
          </Col>
        )}
        {requirementsType === 'dkp_deaths' && (
          <Col>
            <h6 className="text-muted mb-0">Required Deaths</h6>
            <p className="font-weight-bold h4">{formatNumber(requirements.deaths)}</p>
            <p className="text-muted">Achieved: {formatNumber(achieved.deaths)}</p>
          </Col>
        )}
      </Row>
    </Card.Body>
  </Card>
);

const ProgressArc = ({ percentage, label }) => {
  const radius = 80;
  const strokeWidth = 12;
  const normalizedPercentage = Math.min(percentage, 400); // Limit to 400% for visualization
  const circumference = Math.PI * radius;
  const progressLength = (normalizedPercentage / 200) * circumference; // 200% is full circle

  return (
    <div className="progress-arc mb-4">
      <svg width="200" height="120" viewBox="0 0 200 120">
        <path
          d={`M20,100 A${radius},${radius} 0 0,1 180,100`}
          fill="none"
          stroke="#e9ecef"
          strokeWidth={strokeWidth}
        />
        <path
          d={`M20,100 A${radius},${radius} 0 0,1 180,100`}
          fill="none"
          stroke="#4e73df"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progressLength}
          strokeLinecap="round"
        />
        <text x="100" y="105" textAnchor="middle" fontSize="24" fill="#5a5c69" fontWeight="bold">
          {percentage.toFixed(1)}%
        </text>
      </svg>
      <div className="text-center mt-2">
        <h5 className="mb-0 text-muted">{label}</h5>
      </div>
    </div>
  );
};

const KvKProgressWidget = ({ stats, powerRanges, requirementsType, selectedRange }) => {
  const { progressData, requirements, achieved } = useMemo(() => {
    if (!stats || stats.length === 0 || !powerRanges) {
      console.log('No stats or power ranges available');
      return { progressData: [], requirements: { kills: 0, deaths: 0, dkp: 0 }, achieved: { kills: 0, deaths: 0, dkp: 0 } };
    }

    console.log('Calculating progress for:', { requirementsType, selectedRange });

    const latestDate = new Date(Math.max(...stats.map(s => new Date(s.date))));
    const latestStats = stats.filter(s => new Date(s.date).getTime() === latestDate.getTime());

    console.log('Latest stats sample:', latestStats.slice(0, 5));

    const calculateProgress = (statsList) => {
      const totals = statsList.reduce((acc, stat) => {
        acc.requiredKills += parseFloat(stat.required_kills) || 0;
        acc.requiredDeaths += parseFloat(stat.required_deaths) || 0;
        acc.requiredDkp += parseFloat(stat.required_dkp) || 0;
        acc.achievedKills += (parseFloat(stat.t4_kills) || 0) + (parseFloat(stat.t5_kills) || 0);
        acc.achievedDeaths += parseFloat(stat.deaths) || 0;
        acc.achievedDkp += parseFloat(stat.dkp) || 0;
        return acc;
      }, { requiredKills: 0, requiredDeaths: 0, requiredDkp: 0, achievedKills: 0, achievedDeaths: 0, achievedDkp: 0 });

      console.log('Totals:', totals);

      let progressData = [];
      let requirements = {};
      let achieved = {};

      if (requirementsType === 'kills_deaths') {
        const killsProgress = (totals.requiredKills > 0) ? (totals.achievedKills / totals.requiredKills) * 100 : 0;
        const deathsProgress = (totals.requiredDeaths > 0) ? (totals.achievedDeaths / totals.requiredDeaths) * 100 : 0;
        progressData = [
          { label: 'Kills (T4+T5)', progress: killsProgress },
          { label: 'Deaths', progress: deathsProgress }
        ];
        requirements = { kills: totals.requiredKills, deaths: totals.requiredDeaths };
        achieved = { kills: totals.achievedKills, deaths: totals.achievedDeaths };
      } else if (requirementsType === 'dkp') {
        const dkpProgress = (totals.requiredDkp > 0) ? (totals.achievedDkp / totals.requiredDkp) * 100 : 0;
        progressData = [{ label: 'DKP', progress: dkpProgress }];
        requirements = { dkp: totals.requiredDkp };
        achieved = { dkp: totals.achievedDkp };
      } else if (requirementsType === 'dkp_deaths') {
        const dkpProgress = (totals.requiredDkp > 0) ? (totals.achievedDkp / totals.requiredDkp) * 100 : 0;
        const deathsProgress = (totals.requiredDeaths > 0) ? (totals.achievedDeaths / totals.requiredDeaths) * 100 : 0;
        progressData = [
          { label: 'DKP', progress: dkpProgress },
          { label: 'Deaths', progress: deathsProgress }
        ];
        requirements = { dkp: totals.requiredDkp, deaths: totals.requiredDeaths };
        achieved = { dkp: totals.achievedDkp, deaths: totals.achievedDeaths };
      }

      return { progressData, requirements, achieved };
    };

    if (selectedRange === 'overall') {
      return calculateProgress(latestStats);
    } else {
      const rangeStats = latestStats.filter(stat => stat.power_range_id.toString() === selectedRange);
      return calculateProgress(rangeStats);
    }
  }, [stats, powerRanges, requirementsType, selectedRange]);

  console.log('Final progress data:', progressData);
  console.log('Requirements:', requirements);
  console.log('Achieved:', achieved);

  if (progressData.length === 0) {
    return <Card><Card.Body>No progress data available</Card.Body></Card>;
  }

  return (
    <Card className="mb-4 shadow">
      <Card.Body>
        <Card.Title className="mb-4">KvK Progress</Card.Title>
        <RequirementsDisplay requirements={requirements} requirementsType={requirementsType} achieved={achieved} />
        <Row>
          {progressData.map((data, index) => (
            <Col key={index} xs={12} md={6} className="text-center">
              <ProgressArc percentage={data.progress} label={data.label} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default KvKProgressWidget;