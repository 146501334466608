import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { Table, Form, Button } from 'react-bootstrap';

const KvKParticipantStats = ({ kvkId, startDate, endDate }) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('total_dkp');
  const [sortDirection, setSortDirection] = useState('desc');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serverId = searchParams.get('serverId');

  useEffect(() => {
    const fetchStats = async () => {
      if (!serverId) {
        setError('Server ID is missing');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/${kvkId}/participants`, {
          params: { startDate, endDate, serverId },
          withCredentials: true
        });
        setStats(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch participant stats');
        setLoading(false);
      }
    };
    fetchStats();
  }, [kvkId, startDate, endDate, serverId]);

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const filteredAndSortedStats = stats
    .filter(participant => 
      participant.governor_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

  if (loading) return <div>Loading participant stats...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="kvk-participant-stats">
      <h2>KvK Participant Statistics</h2>
      <Form.Control 
        type="text" 
        placeholder="Search by governor name" 
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-3"
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort('governor_name')}>Governor Name</th>
            <th onClick={() => handleSort('power_increase')}>Power Increase</th>
            <th onClick={() => handleSort('kill_points_increase')}>Kill Points Increase</th>
            <th onClick={() => handleSort('t4_kills_increase')}>T4 Kills</th>
            <th onClick={() => handleSort('t4_dkp')}>T4 DKP</th>
            <th onClick={() => handleSort('t5_kills_increase')}>T5 Kills</th>
            <th onClick={() => handleSort('t5_dkp')}>T5 DKP</th>
            <th onClick={() => handleSort('deaths_increase')}>Deaths</th>
            <th onClick={() => handleSort('deaths_dkp')}>Deaths DKP</th>
            <th onClick={() => handleSort('total_dkp')}>Total DKP</th>
            <th>Alliance</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedStats.map(participant => (
            <tr key={participant.governor_id}>
              <td>{participant.governor_name}</td>
              <td>{participant.power_increase.toLocaleString()}</td>
              <td>{participant.kill_points_increase.toLocaleString()}</td>
              <td>{participant.t4_kills_increase.toLocaleString()}</td>
              <td>{participant.t4_dkp.toLocaleString()}</td>
              <td>{participant.t5_kills_increase.toLocaleString()}</td>
              <td>{participant.t5_dkp.toLocaleString()}</td>
              <td>{participant.deaths_increase.toLocaleString()}</td>
              <td>{participant.deaths_dkp.toLocaleString()}</td>
              <td>{participant.total_dkp.toLocaleString()}</td>
              <td>{participant.alliance}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default KvKParticipantStats;