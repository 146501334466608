import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import axios from 'axios';

const SubscriptionOwnerRoute = ({ children }) => {
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(true);
  const { serverId } = useParams();

  useEffect(() => {
    const checkSubscriptionOwnership = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/check-ownership/${serverId}`, {
          withCredentials: true
        });
        setIsOwner(response.data.isOwner);
      } catch (error) {
        console.error('Error checking subscription ownership:', error);
        setIsOwner(false);
      } finally {
        setLoading(false);
      }
    };

    checkSubscriptionOwnership();
  }, [serverId]);

  if (loading) {
    return <div>Checking permissions...</div>;
  }

  return isOwner ? children : <Navigate to="/dashboard" replace />;
};

export default SubscriptionOwnerRoute;