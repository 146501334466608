import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Form, Button, Alert } from 'react-bootstrap';

const PermissionsConfig = ({ serverId }) => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissionDefinitions = {
    canViewDiscordLinks: {
      name: "View Discord Linked Accs",
      description: "Allows viewing of user discord account"
    },
    compareUsers: {
      name: 'Compare Users',
      description: 'Allows comparing statistics between users'
    },
    viewUserList: {
      name: 'View User List',
      description: 'Allows viewing the list of all users'
    },
    viewUserStats: {
      name: 'View User Stats',
      description: 'Allows viewing individual user statistics'
    },
    viewVerifications: {
      name: "View Verifications",
      description: "Allows viewing of user verifications"
    },

    extendSubscription: {
      name: "Extend Subscription",
      description: "Allows extending server subscriptions"
    },
    accessServerVerification: {
      name: "Access Server Verification",
      description: "Grants access to server verification features"
    },
    uploadStats: {
      name: "Upload Stats",
      description: "Allows uploading of server statistics"
    },
    viewKvK: {
      name: "View KvK",
      description: "Allows uploading of server statistics"
    },

    viewStats: {
      name: "View Stats",
      description: "Allows viewing of server statistics"
    }
  };

  const fetchRolesAndPermissions = useCallback(async () => {
    if (!serverId) {
      console.log('No server selected, skipping fetch');
      setLoading(false);
      return;
    }
    
    setLoading(true);
    setError(null);
    try {
      const [rolesRes, permissionsRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/roles`, { withCredentials: true }),
        axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/permissions`, { withCredentials: true })
      ]);
      
      console.log('Roles response:', rolesRes.data);
      console.log('Permissions response:', permissionsRes.data);

      if (Array.isArray(rolesRes.data)) {
        setRoles(rolesRes.data);
      } else {
        console.error('Roles data is not an array:', rolesRes.data);
        setRoles([]);
      }
      
      const permissionsObj = Array.isArray(permissionsRes.data) 
        ? permissionsRes.data.reduce((acc, perm) => {
            if (!acc[perm.discord_role_id]) {
              acc[perm.discord_role_id] = {};
            }
            acc[perm.discord_role_id][perm.permission_type] = true;
            return acc;
          }, {})
        : {};
      
      console.log('Transformed permissions:', permissionsObj);
      setPermissions(permissionsObj);
    } catch (err) {
      console.error('Error fetching roles and permissions:', err);
      setError('Failed to fetch roles and permissions: ' + err.message);
      setRoles([]);
      setPermissions({});
    } finally {
      setLoading(false);
    }
  }, [serverId]);

  useEffect(() => {
    fetchRolesAndPermissions();
  }, [fetchRolesAndPermissions]);

  const handlePermissionChange = async (roleId, permissionType, isChecked) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/server/${serverId}/permissions`, {
        roleId,
        permissionType,
        hasPermission: isChecked
      }, { withCredentials: true });
      setPermissions(prev => ({
        ...prev,
        [roleId]: {
          ...prev[roleId],
          [permissionType]: isChecked
        }
      }));
    } catch (err) {
      setError('Failed to update permission: ' + err.message);
    }
  };

  const syncDiscordRoles = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/server/${serverId}/sync-roles`, {}, { withCredentials: true });
      await fetchRolesAndPermissions();
    } catch (err) {
      setError('Failed to sync Discord roles: ' + err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  
  return (
    <div className="permissions-config">
      <h3 className="mb-4">Permissions Configuration</h3>
      {error && <Alert variant="danger">{error}</Alert>}
      <Button onClick={syncDiscordRoles} className="mb-4">Sync Discord Roles</Button>
      {roles && roles.length > 0 ? (
        roles.map((role, index) => (
          <div key={role.role_id || `role-${index}`} className="mb-4">
            <h4>{role.role_name || `Role ${index + 1}`}</h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Permission</th>
                  <th>Description</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(permissionDefinitions).map(([permKey, permDef]) => (
                  <tr key={permKey}>
                    <td>{permDef.name}</td>
                    <td>{permDef.description}</td>
                    <td>
                      <Form.Check 
                        type="switch"
                        id={`${role.role_id || index}-${permKey}`}
                        checked={permissions[role.role_id]?.[permKey] || false}
                        onChange={(e) => handlePermissionChange(role.role_id, permKey, e.target.checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))
      ) : (
        <Alert variant="info">No roles found. Try syncing Discord roles.</Alert>
      )}
    </div>
  );
};

export default PermissionsConfig;