import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NotificationsConfig = ({ serverId }) => {
  const [config, setConfig] = useState({
    discordChannelId: '',
    enablePrivateMessages: false,
    notifyNewSubscriptions: false,
    allowDiscordApproval: false,
    kvkNotificationsChannelId: ''

  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotificationConfig = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/notifications-config`, { withCredentials: true });
        setConfig(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch notification configuration');
        setLoading(false);
      }
    };

    fetchNotificationConfig();
  }, [serverId]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setConfig(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/server/${serverId}/notifications-config`, config, { withCredentials: true });
      alert('Notification configuration updated successfully');
    } catch (err) {
      setError('Failed to update notification configuration');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="notifications-config">
      <h3>Notifications Configuration</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="discordChannelId">Discord Channel ID</label>
          <input
            type="text"
            className="form-control"
            id="discordChannelId"
            name="discordChannelId"
            value={config.discordChannelId}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="kvkNotificationsChannelId">KvK Notifications Discord Channel ID</label>
          <input
            type="text"
            className="form-control"
            id="kvkNotificationsChannelId"
            name="kvkNotificationsChannelId"
            value={config.kvkNotificationsChannelId}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="enablePrivateMessages"
            name="enablePrivateMessages"
            checked={config.enablePrivateMessages}
            onChange={handleInputChange}
          />
          <label className="form-check-label" htmlFor="enablePrivateMessages">
            Enable Private Messages
          </label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="notifyNewSubscriptions"
            name="notifyNewSubscriptions"
            checked={config.notifyNewSubscriptions}
            onChange={handleInputChange}
          />
          <label className="form-check-label" htmlFor="notifyNewSubscriptions">
            Notify New Subscriptions
          </label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="allowDiscordApproval"
            name="allowDiscordApproval"
            checked={config.allowDiscordApproval}
            onChange={handleInputChange}
          />
          <label className="form-check-label" htmlFor="allowDiscordApproval">
            Allow Subscription Approval via Discord
          </label>
        </div>
        <button type="submit" className="btn btn-primary mt-3">Save Configuration</button>
      </form>
    </div>
  );
};

export default NotificationsConfig;