import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DiscordInvite = () => {
  const [inviteLink, setInviteLink] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInviteLink = async () => {
      try {
        console.log('Fetching invite link...');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/discord-invite/invite-link`);
        console.log('Response:', response.data);
        setInviteLink(response.data.inviteLink);
      } catch (err) {
        console.error('Error fetching invite link:', err);
        setError(`Nie uda�o si� pobra� linku zaproszenia: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchInviteLink();
  }, []);

  if (loading) {
    return <p>�adowanie linku zaproszenia...</p>;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Dodaj bota do nowego serwera</h5>
        {inviteLink ? (
          <a href={inviteLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
            Dodaj bota do serwera Discord
          </a>
        ) : (
          <p>Nie uda�o si� za�adowa� linku zaproszenia.</p>
        )}
      </div>
    </div>
  );
};

export default DiscordInvite;