// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PromoCodeManagement from './PromoCodeManagement';
import { useTranslation } from 'react-i18next';


function Dashboard({ user }) {
  const { t } = useTranslation(['dashboard', 'translation']);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        if (user.role === 'admin' || user.role === 'owner') {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard`, { withCredentials: true });
          setDashboardData(response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('Failed to load dashboard data');
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [user]);

  const renderProfileCompletion = () => {
    if (user && !user.isProfileComplete && (user.role === 'admin' || user.role === 'owner')) {
      return (
        <div className="alert alert-warning">
          Please complete your profile to access all features.
        </div>
      );
    }
    return null;
  };

  const renderOwnerDashboard = () => (
    <div>
      <h1 className="h3 mb-4 text-gray-800">{t('dashboard:owner.title')}</h1>
      {renderProfileCompletion()}
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{t('dashboard:manageServers')}</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">10 Servers</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-server fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">{t('dashboard:owner.totalRevenue')}</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">{t('dashboard:owner.manageAdmins')}</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">5 Admins</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-users-cog fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">{t('dashboard:owner.revenueOverview')}</h6>
            </div>
            <div className="card-body">
              <div className="chart-area">
                <canvas id="myAreaChart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">{t('dashboard:owner.promoCodeManage')}</h6>
            </div>
            <div className="card-body">
              <PromoCodeManagement />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderAdminDashboard = () => (
    <div>
      <h1 className="h3 mb-4 text-gray-800">{t('dashboard:admin.title')}</h1>
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{t('dashboard:manageServers')}</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboardData?.managedServers?.length || 0}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-server fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">{t('dashboard:activeSubscriptions')}</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboardData?.activeSubscriptions?.length || 0}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">{t('dashboard:totalUsers')}</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboardData?.totalUsers || 0}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-users fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderUserDashboard = () => (
    <div>
      <h1 className="h3 mb-4 text-gray-800">User Dashboard</h1>
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Active Subscriptions</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">2</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Next Payment</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">$19.99</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">My Subscriptions</h6>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Server Name</th>
                      <th>Plan</th>
                      <th>Start Date</th>
                      <th>Next Billing Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cool Discord</td>
                      <td>Pro</td>
                      <td>2023/04/25</td>
                      <td>2023/05/25</td>
                      <td><span className="badge badge-success">Active</span></td>
                    </tr>
                    <tr>
                      <td>Gaming Community</td>
                      <td>Basic</td>
                      <td>2023/03/01</td>
                      <td>2023/06/01</td>
                      <td><span className="badge badge-success">Active</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="dashboard">
      {user.role === 'admin' && renderAdminDashboard()}
      {user.role === 'owner' && renderOwnerDashboard()}
      {user.role === 'user' && renderUserDashboard()}
    </div>
  );
}

export default Dashboard;