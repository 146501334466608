import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function ReportsPage() {
  const [revenueData, setRevenueData] = useState([]);
  const [userGrowthData, setUserGrowthData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const end = new Date();
    const start = new Date();
    start.setMonth(start.getMonth() - 1);
    setStartDate(start.toISOString().split('T')[0]);
    setEndDate(end.toISOString().split('T')[0]);
  }, []);

  const fetchReports = async () => {
    setLoading(true);
    setError('');
    try {
      const [revenueResponse, userGrowthResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/reports/revenue`, {
          params: { startDate, endDate },
          withCredentials: true
        }),
        axios.get(`${process.env.REACT_APP_API_URL}/reports/user-growth`, {
          params: { startDate, endDate },
          withCredentials: true
        })
      ]);
      setRevenueData(revenueResponse.data);
      setUserGrowthData(userGrowthResponse.data);
    } catch (err) {
      setError('Failed to fetch reports');
      console.error('Error fetching reports:', err);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Reports</h1>
      <p className="mb-4">View revenue and user growth reports.</p>

      {error && <div className="alert alert-danger">{error}</div>}

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Generate Report</h6>
        </div>
        <div className="card-body">
          <form onSubmit={(e) => { e.preventDefault(); fetchReports(); }}>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4">
                <label>&nbsp;</label>
                <button type="submit" className="btn btn-primary btn-block">
                  Generate Reports
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Revenue Report</h6>
            </div>
            <div className="card-body">
              {revenueData.length > 0 ? (
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={revenueData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={formatDate} />
                    <YAxis />
                    <Tooltip labelFormatter={formatDate} />
                    <Legend />
                    <Line type="monotone" dataKey="revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No revenue data available for the selected date range.</p>
              )}
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">User Growth Report</h6>
            </div>
            <div className="card-body">
              {userGrowthData.length > 0 ? (
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={userGrowthData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={formatDate} />
                    <YAxis />
                    <Tooltip labelFormatter={formatDate} />
                    <Legend />
                    <Line type="monotone" dataKey="new_users" stroke="#82ca9d" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No user growth data available for the selected date range.</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ReportsPage;