import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';

const StatsArchive = () => {
    const { t } = useTranslation();
    const { serverId } = useParams();
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchDates();
    }, [serverId]);

const fetchDates = async () => {
  try {
    setLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/rok-stats/${serverId}/archive`, {
      withCredentials: true
    });
    console.log('Archive response:', response.data);
    if (Array.isArray(response.data)) {
      setDates(response.data);
    } else if (response.data && response.data.message) {
      setDates([]);
      setMessage(response.data.message);
    } else {
      console.error('Received unexpected data:', response.data);
      setDates([]);
      setMessage(t('Received invalid data from server'));
    }
  } catch (error) {
    console.error('Error fetching dates:', error);
    if (error.response && error.response.status === 401) {
      setMessage(t('Authentication error. Please log in again.'));
    } else {
      setMessage(t('errorFetchingDates'));
    }
    setDates([]);
  } finally {
    setLoading(false);
  }
};

    const handleDelete = async (date) => {
        setSelectedDate(date);
    };

    const confirmDelete = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/rok-stats/${serverId}/delete`, 
                { date: selectedDate, password },
                { withCredentials: true }
            );
            if (response.data.success) {
                setMessage(t('statsDeleted'));
                setDates(dates.filter(d => d.date !== selectedDate));
                setSelectedDate('');
                setPassword('');
            } else {
                setMessage(t('incorrectPassword'));
            }
        } catch (error) {
            setMessage(t('errorDeletingStats'));
            console.error('Error deleting stats:', error);
        }
    };

    const handleExport = async (date) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/rok-stats/${serverId}/export/${date}`, {
                responseType: 'blob',
                withCredentials: true
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `stats_${date}.csv`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error exporting stats:', error);
            setMessage(t('errorExportingStats'));
        }
    };

    if (loading) {
        return <div className="text-center mt-5">{t('loading')}</div>;
    }

    return (
        <div className="container mt-4">
            <h1>{t('statsArchive')}</h1>
            {message && <div className="alert alert-info">{message}</div>}
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{t('date')}</th>
                        <th>{t('totalPower')}</th>
                        <th>{t('totalKillPoints')}</th>
                        <th>{t('totalDeads')}</th>
                        <th>{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {dates.map(date => (
                        <tr key={date.date}>
                            <td>{date.date}</td>
                            <td>{date.total_power}</td>
                            <td>{date.total_kill_points}</td>
                            <td>{date.total_deads}</td>
                            <td>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="text-danger mr-2 cursor-pointer"
                                    onClick={() => handleDelete(date.date)}
                                />
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    className="text-primary cursor-pointer"
                                    onClick={() => handleExport(date.date)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {selectedDate && (
                <div className="mt-4">
                    <h3>{t('deleteStats')}</h3>
                    <p>{t('enterPasswordToDeleteStats', { date: selectedDate })}</p>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button className="btn btn-danger mr-2" onClick={confirmDelete}>{t('confirm')}</button>
                    <button className="btn btn-secondary" onClick={() => setSelectedDate('')}>{t('cancel')}</button>
                </div>
            )}
        </div>
    );
};

export default StatsArchive;