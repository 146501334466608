// W NotificationHistory.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';

const NotificationHistory = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-notifications/history`, { withCredentials: true });
      setNotifications(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching notification history:', error);
      setNotifications([]);
    }
  };

  return (
    <div>
      <h2>Notification History</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Message</th>
            <th>Sent At</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification, index) => (
            <tr key={notification.id || index}>
              <td>{notification.type}</td>
              <td>{notification.message}</td>
              <td>{new Date(notification.sent_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default NotificationHistory;