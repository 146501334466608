import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function UpgradeSuccess() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmUpgrade = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (!sessionId) {
        setError('Invalid session ID');
        setLoading(false);
        return;
      }

      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/subscription/confirm-plan-change`,
          { sessionId },
          { withCredentials: true }
        );
        setLoading(false);
      } catch (err) {
        setError('Failed to confirm plan upgrade');
        setLoading(false);
      }
    };

    confirmUpgrade();
  }, [location]);

  if (loading) return <div>Confirming your plan upgrade...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Plan Upgrade Successful!</h2>
      <p>Your subscription has been successfully upgraded.</p>
      <button onClick={() => navigate('/manage-servers')}>Back to Manage Servers</button>
    </div>
  );
}

export default UpgradeSuccess;