import React, { useState, useMemo } from 'react';
import { Table, Button, Collapse, Form, InputGroup } from 'react-bootstrap';


const KvKParticipantsTable = ({ stats, powerRanges, requirementsType, canViewDiscordLinks }) => {
  console.log('KvKParticipantsTable - canViewDiscordLinks:', canViewDiscordLinks);
  console.log('KvKParticipantsTable - Sample stat:', stats[0]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    governorName: '',
    governorId: '',
    startPower: { min: '', max: '' },
    progressPercentage: { min: '', max: '' },
    discordUsername: ''
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const participantsData = useMemo(() => {
    const latestStats = stats.reduce((acc, stat) => {
      if (!acc[stat.governor_id] || new Date(stat.date) > new Date(acc[stat.governor_id].date)) {
        acc[stat.governor_id] = stat;
      }
      return acc;
    }, {});

    return Object.values(latestStats).map(stat => {
      const powerRange = powerRanges.find(range => 
        stat.start_power >= range.min_power && stat.start_power < range.max_power
      );

      let progressPercentage = 0;
      let dkpProgressPercentage = 0;
      let deathsProgressPercentage = 0;

      if (requirementsType === 'kills_deaths') {
        const killsProgress = stat.required_kills > 0 ? (stat.kill_points / stat.required_kills) * 100 : 0;
        deathsProgressPercentage = stat.required_deaths > 0 ? (stat.deaths / stat.required_deaths) * 100 : 0;
        progressPercentage = Math.min(killsProgress, deathsProgressPercentage);
      } else if (requirementsType === 'dkp') {
        progressPercentage = stat.required_dkp > 0 ? (stat.dkp / stat.required_dkp) * 100 : 0;
      } else if (requirementsType === 'dkp_deaths') {
        dkpProgressPercentage = stat.required_dkp > 0 ? (stat.dkp / stat.required_dkp) * 100 : 0;
        deathsProgressPercentage = stat.required_deaths > 0 ? (stat.deaths / stat.required_deaths) * 100 : 0;
        progressPercentage = Math.min(dkpProgressPercentage, deathsProgressPercentage);
      }

      return {
        ...stat,
        powerRange: powerRange ? `${powerRange.min_power.toLocaleString()} - ${powerRange.max_power === Infinity ? '?' : powerRange.max_power.toLocaleString()}` : 'N/A',
        progressPercentage: progressPercentage.toFixed(2),
        dkpProgressPercentage: dkpProgressPercentage.toFixed(2),
        deathsProgressPercentage: deathsProgressPercentage.toFixed(2)
      };
    });
  }, [stats, powerRanges, requirementsType]);

  const filteredAndSortedParticipants = useMemo(() => {
    let filteredData = participantsData.filter(participant => {
      
      return (
        (filters.governorName === '' || participant.governor_name.toLowerCase().includes(filters.governorName.toLowerCase())) &&
        (filters.governorId === '' || participant.governor_id.includes(filters.governorId)) &&
        (filters.startPower.min === '' || participant.start_power >= Number(filters.startPower.min)) &&
        (filters.startPower.max === '' || participant.start_power <= Number(filters.startPower.max)) &&
        (filters.progressPercentage.min === '' || parseFloat(participant.progressPercentage) >= Number(filters.progressPercentage.min)) &&
        (filters.progressPercentage.max === '' || parseFloat(participant.progressPercentage) <= Number(filters.progressPercentage.max)) &&
        (filters.discordUsername === '' || 
          (participant.discord_username && 
           participant.discord_username.toLowerCase().includes(filters.discordUsername.toLowerCase())))
      );
    });
    if (sortConfig.key !== null) {
      filteredData.sort((a, b) => {
        const aValue = Number(a[sortConfig.key]);
        const bValue = Number(b[sortConfig.key]);
        
        if (isNaN(aValue) || isNaN(bValue)) {
          // Je�li kt�ra� z warto�ci nie jest liczb�, sortuj alfabetycznie
          return sortConfig.direction === 'ascending' 
            ? String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]))
            : String(b[sortConfig.key]).localeCompare(String(a[sortConfig.key]));
        }
        
        // Sortowanie liczbowe
        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
      });
    }

    return filteredData;
  }, [participantsData, filters, sortConfig]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleRangeFilterChange = (e) => {
    const { name, value } = e.target;
    const [filterName, rangeType] = name.split('.');
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: {
        ...prevFilters[filterName],
        [rangeType]: value
      }
    }));
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const formatNumber = (num) => num.toLocaleString();

  const getSortIndicator = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? ' ^' : ' �';
    }
    return '';
  };

  return (
    <>
      <Button 
        onClick={() => setShowFilters(!showFilters)} 
        aria-controls="filter-collapse" 
        aria-expanded={showFilters}
        className="mb-3"
      >
        {showFilters ? 'Hide Filters' : 'Show Filters'}
      </Button>
      <Collapse in={showFilters}>
        <div id="filter-collapse" className="mb-3">
          <Form>
            <Form.Group>
              <Form.Label>Governor Name</Form.Label>
              <Form.Control 
                type="text" 
                name="governorName" 
                value={filters.governorName} 
                onChange={handleFilterChange}
                placeholder="Filter by governor name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Governor ID</Form.Label>
              <Form.Control 
                type="text" 
                name="governorId" 
                value={filters.governorId} 
                onChange={handleFilterChange}
                placeholder="Filter by governor ID"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Start Power</Form.Label>
              <InputGroup>
                <Form.Control 
                  type="number" 
                  name="startPower.min" 
                  value={filters.startPower.min} 
                  onChange={handleRangeFilterChange}
                  placeholder="Min"
                />
                <InputGroup.Text>-</InputGroup.Text>
                <Form.Control 
                  type="number" 
                  name="startPower.max" 
                  value={filters.startPower.max} 
                  onChange={handleRangeFilterChange}
                  placeholder="Max"
                />
              </InputGroup>
            </Form.Group>
          {canViewDiscordLinks && (
            <Form.Group>
              <Form.Label>Discord Username</Form.Label>
              <Form.Control 
                type="text" 
                name="discordUsername" 
                value={filters.discordUsername} 
                onChange={handleFilterChange}
                placeholder="Filter by Discord username"
              />
            </Form.Group>
          )}
            <Form.Group>
              <Form.Label>Progress Percentage</Form.Label>
              <InputGroup>
                <Form.Control 
                  type="number" 
                  name="progressPercentage.min" 
                  value={filters.progressPercentage.min} 
                  onChange={handleRangeFilterChange}
                  placeholder="Min"
                />
                <InputGroup.Text>-</InputGroup.Text>
                <Form.Control 
                  type="number" 
                  name="progressPercentage.max" 
                  value={filters.progressPercentage.max} 
                  onChange={handleRangeFilterChange}
                  placeholder="Max"
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      </Collapse>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Governor Name</th>
            <th>Governor ID</th>
            {canViewDiscordLinks && (
              <th onClick={() => requestSort('discord_username')} style={{cursor: 'pointer'}}>
                Discord Username {getSortIndicator('discord_username')}
              </th>
            )}
            <th>Power Range</th>
            <th onClick={() => requestSort('start_power')} style={{cursor: 'pointer'}}>
              Start Power {getSortIndicator('start_power')}
            </th>
            <th onClick={() => requestSort('dkp')} style={{cursor: 'pointer'}}>
              DKP {getSortIndicator('dkp')}
            </th>
            <th onClick={() => requestSort('t4_kills')} style={{cursor: 'pointer'}}>
              T4 Kills {getSortIndicator('t4_kills')}
            </th>
            <th onClick={() => requestSort('t5_kills')} style={{cursor: 'pointer'}}>
              T5 Kills {getSortIndicator('t5_kills')}
            </th>
            <th onClick={() => requestSort('deaths')} style={{cursor: 'pointer'}}>
              Dead Troops {getSortIndicator('deaths')}
            </th>
            <th onClick={() => requestSort('kill_points')} style={{cursor: 'pointer'}}>
              Kill Points {getSortIndicator('kill_points')}
            </th>
            {requirementsType === 'dkp_deaths' && (
              <>
                <th onClick={() => requestSort('dkpProgressPercentage')} style={{cursor: 'pointer'}}>
                  DKP Progress (%) {getSortIndicator('dkpProgressPercentage')}
                </th>
                <th onClick={() => requestSort('deathsProgressPercentage')} style={{cursor: 'pointer'}}>
                  Deaths Progress (%) {getSortIndicator('deathsProgressPercentage')}
                </th>
              </>
            )}
            <th onClick={() => requestSort('progressPercentage')} style={{cursor: 'pointer'}}>
              Overall Progress (%) {getSortIndicator('progressPercentage')}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedParticipants.map((participant) => (
            <tr key={participant.governor_id}>
              <td>{participant.governor_name}</td>
              <td>{participant.governor_id}</td>
              {canViewDiscordLinks && (
                <td>{participant.discord_username || 'N/A'}</td>
              )}
              <td>{participant.powerRange}</td>
              <td>{formatNumber(participant.start_power)}</td>
              <td>{formatNumber(participant.dkp)}</td>
              <td>{formatNumber(participant.t4_kills)}</td>
              <td>{formatNumber(participant.t5_kills)}</td>
              <td>{formatNumber(participant.deaths)}</td>
              <td>{formatNumber(participant.kill_points)}</td>
              {requirementsType === 'dkp_deaths' && (
                <>
                  <td>{participant.dkpProgressPercentage}%</td>
                  <td>{participant.deathsProgressPercentage}%</td>
                </>
              )}
              <td>{participant.progressPercentage}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default KvKParticipantsTable;