// W pliku client/src/components/SubscriptionSuccess.js

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function SubscriptionSuccess() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmSubscription = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (!sessionId) {
        setError('Invalid session ID');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/subscription/confirm`,
          { sessionId },
          { withCredentials: true }
        );

        if (response.data.success) {
          // Mo�esz tutaj doda� dodatkow� logik�, np. wy�wietlenie komunikatu o sukcesie
          navigate('/dashboard'); // Przekierowanie do dashboardu po udanym potwierdzeniu
        } else {
          setError('Failed to confirm subscription');
        }
      } catch (err) {
        console.error('Error confirming subscription:', err);
        setError('Failed to confirm subscription');
      } finally {
        setLoading(false);
      }
    };

    confirmSubscription();
  }, [location, navigate]);

  if (loading) return <div>Confirming your subscription...</div>;
  if (error) return <div>Error: {error}</div>;

  return <div>Subscription confirmed successfully! Redirecting to dashboard...</div>;
}

export default SubscriptionSuccess;