import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card } from 'react-bootstrap';

const KvKPowerDeathsChart = ({ stats, viewMode, selectedRange, powerRanges }) => {
  const processedStats = useMemo(() => {
    if (!stats || stats.length === 0) return [];

    // Grupuj dane wed�ug daty
    const groupedStats = stats.reduce((acc, stat) => {
      const date = stat.date.split('T')[0];
      if (!acc[date]) {
        acc[date] = { power: 0, deaths: 0 };
      }
      acc[date].power += parseInt(stat.power) || 0;
      acc[date].deaths += parseInt(stat.deaths) || 0;
      return acc;
    }, {});

    // Konwertuj zgrupowane dane na tablic� i sortuj wed�ug daty
    const sortedStats = Object.entries(groupedStats)
      .map(([date, values]) => ({ date, ...values }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    const startDate = new Date(sortedStats[0].date);
    let prevDayStats = null;

    return sortedStats.map((stat, index) => {
      const currentDate = new Date(stat.date);
      const daysSinceStart = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24));

      let processedDay = {
        date: stat.date,
        daysSinceStart,
        power: stat.power,
        deaths: stat.deaths
      };

      if (viewMode === 'fromStart' && index > 0) {
        processedDay.power -= sortedStats[0].power;
        processedDay.deaths -= sortedStats[0].deaths;
      } else if (viewMode === 'dailyChange' && prevDayStats) {
        processedDay.power -= prevDayStats.power;
        processedDay.deaths -= prevDayStats.deaths;
      }

      prevDayStats = stat;
      return processedDay;
    });
  }, [stats, viewMode]);

  const formatYAxis = (value) => {
    if (value >= 1000000000) return `${(value / 1000000000).toFixed(1)}B`;
    if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
    if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
    return value;
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>Power and Deaths Chart</Card.Title>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={processedStats}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" tickFormatter={formatYAxis} />
            <YAxis yAxisId="right" orientation="right" tickFormatter={formatYAxis} />
            <Tooltip formatter={(value) => formatYAxis(value)} />
            <Legend />
            <Line yAxisId="left" type="monotone" dataKey="power" stroke="#8884d8" name="Power" />
            <Line yAxisId="right" type="monotone" dataKey="deaths" stroke="#82ca9d" name="Deaths" />
          </LineChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};

export default KvKPowerDeathsChart;