import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Navigate } from 'react-router-dom';

const VerificationManagement = () => {
  const [pendingVerifications, setPendingVerifications] = useState([]);
  const [verifiedAccounts, setVerifiedAccounts] = useState([]);
  const [hasPermission, setHasPermission] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('pending');
  const [currentImage, setCurrentImage] = useState('');
  const { serverId } = useParams();

  useEffect(() => {
    const checkPermission = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/check-permission`, { withCredentials: true });
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setHasPermission(false);
        }
        setLoading(false);
      }
    };
    checkPermission();
  }, [serverId]);

  useEffect(() => {
    if (hasPermission) {
      fetchPendingVerifications();
      fetchVerifiedAccounts();
    }
  }, [serverId, hasPermission]);

  const fetchPendingVerifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/verification-management/pending`, { withCredentials: true });
      setPendingVerifications(response.data);
    } catch (error) {
      console.error('Error fetching pending verifications:', error);
    }
  };

  const fetchVerifiedAccounts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/verification-management/verified`, { withCredentials: true });
      setVerifiedAccounts(response.data);
    } catch (error) {
      console.error('Error fetching verified accounts:', error);
    }
  };

  const handleVerification = async (accountId, approved) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/server/${serverId}/verification-management/verify/${accountId}`, { approved }, { withCredentials: true });
      fetchPendingVerifications();
      fetchVerifiedAccounts();
    } catch (error) {
      console.error('Error updating verification:', error);
      alert('Failed to update verification');
    }
  };

const showImage = (imageUrl) => {
  // Upewnij si�, �e imageUrl to tylko nazwa pliku, a nie pe�na �cie�ka
  const fileName = imageUrl.split('/').pop();
  setCurrentImage(`${process.env.REACT_APP_API_URL}/server/${serverId}/verification-image/${fileName}`);
  window.$('#imageModal').modal('show');
};
  if (loading) return <div>Loading...</div>;
  if (!hasPermission) return <Navigate to="/dashboard" replace />;

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-2 text-gray-800">Verification Management</h1>
      <p className="mb-4">Manage pending verifications and view verified accounts.</p>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <a className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('pending')}>Pending Verifications</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${activeTab === 'verified' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('verified')}>Verified Accounts</a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>RID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {(activeTab === 'pending' ? pendingVerifications : verifiedAccounts).map((account) => (
                  <tr key={account.id}>
                    <td>{account.username}</td>
                    <td>{account.rid}</td>
                    <td>
                      <button className="btn btn-info btn-sm mr-2" onClick={() => showImage(account.verification_image_url)}>
                        <i className="fas fa-eye"></i> View Image
                      </button>
                      {activeTab === 'pending' && (
                        <>
                          <button className="btn btn-success btn-sm mr-2" onClick={() => handleVerification(account.id, true)}>
                            <i className="fas fa-check"></i> Approve
                          </button>
                          <button className="btn btn-danger btn-sm" onClick={() => handleVerification(account.id, false)}>
                            <i className="fas fa-times"></i> Reject
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="imageModalLabel">Verification Image</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={currentImage} alt="Verification" style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationManagement;