import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import dashboardEN from './locales/en/dashboard.json';
import loginEN from './locales/en/login.json';
import rok1EN from './locales/en/rok1.json';
import homeEN from './locales/en/home.json';

import translationPL from './locales/pl/translation.json';
import dashboardPL from './locales/pl/dashboard.json';

const resources = {
  en: {
    translation: translationEN,
    home: homeEN,
    login: loginEN,
    rok1: rok1EN,
    dashboard: dashboardEN
  },
  pl: {
    translation: translationPL,
    dashboard: dashboardPL
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    ns: ['translation', 'dashboard'],
    defaultNS: 'translation'
  });

export default i18n;