import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Table } from 'react-bootstrap';

const KvKModalEvents = ({ kvkId, serverId }) => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    name: '',
    description: '',
    startTime: '',
  });

  useEffect(() => {
    if (kvkId && serverId) {
      fetchEvents();
    }
  }, [kvkId, serverId]);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/events`, {
        params: { eventType: 'kvk', kvkId },
        withCredentials: true
      });
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching KvK events:', error);
    }
  };

  const handleInputChange = (e) => {
    setNewEvent({ ...newEvent, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const eventData = {
        ...newEvent,
        eventType: 'kvk',
        kvkId: kvkId
      };
      console.log('Sending event data:', eventData);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/server/${serverId}/events`, eventData, { withCredentials: true });
      console.log('Event creation response:', response.data);
      setNewEvent({ name: '', description: '', startTime: '' });
      fetchEvents();
    } catch (error) {
      console.error('Error creating KvK event:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  };

  return (
    <div>
      <h3>KvK Events (KvK ID: {kvkId}, Server ID: {serverId})</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Event Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={newEvent.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Event Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={newEvent.description}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Start Time</Form.Label>
          <Form.Control
            type="datetime-local"
            name="startTime"
            value={newEvent.startTime}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Button type="submit">Create Event</Button>
      </Form>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Start Time</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <tr key={event.id}>
              <td>{event.name}</td>
              <td>{event.description}</td>
              <td>{new Date(event.start_time).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default KvKModalEvents;