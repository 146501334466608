import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MenuItem = ({ icon, label, items, isActive, onClick }) => {
  return (
    <li className={`nav-item ${isActive ? 'active' : ''}`}>
      <a 
        className="nav-link" 
        href="#" 
        onClick={(e) => { e.preventDefault(); onClick(); }}
      >
        <i className={`fas fa-fw ${icon}`}></i>
        <span>{label}</span>
      </a>
      {isActive && items && items.length > 0 && (
        <div className="collapse show" id={`collapse-${label}`}>
          <div className="bg-white py-2 collapse-inner rounded">
            {items.map((item, index) => (
              <Link key={index} className="collapse-item" to={item.link}>
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </li>
  );
};

const Menu = ({ user, selectedServer, isSubscriptionOwner, userPermissions }) => {
  const { t } = useTranslation();
  const [activeMenu, setActiveMenu] = useState(null);

  if (!user) return null;

  const menuItems = [
    {
      icon: 'fa-tachometer-alt',
      label: t('dashboard'),
      link: '/dashboard',
      items: []
    },
    {
      icon: 'fa-users',
      label: t('accounts'),
      items: selectedServer ? [
        { label: t('addRokAccount'), link: `/server/${selectedServer}/add-rok-account` },
        { label: t('rokAccounts'), link: `/server/${selectedServer}/rok-accounts` }
      ] : []
    },
    {
      icon: 'fa-chart-line',
      label: t('statistics'),
      items: selectedServer && (isSubscriptionOwner || userPermissions.uploadStats || userPermissions.viewStats) ? [
        ...(isSubscriptionOwner || userPermissions.uploadStats ? [{ label: t('uploadStats'), link: `/server/${selectedServer}/upload-stats` }] : []),
        ...(isSubscriptionOwner || userPermissions.viewStats ? [{ label: t('viewStats'), link: `/server/${selectedServer}/view-stats` }] : [])
      ] : []
    },
    {
      icon: 'fa-crown',
      label: t('kvk'),
      items: selectedServer && (isSubscriptionOwner || userPermissions.manageKvK) ? [
        { label: t('manageKvK'), link: `/server/${selectedServer}/manage-kvk` }
      ] : []
    },
    ...(user.role === 'admin' || user.role === 'owner' ? [
      {
        icon: 'fa-cogs',
        label: t('administration'),
        items: [
          ...(user.role === 'admin' ? [
            { label: t('manageServers'), link: '/manage-servers' },
            { label: t('promoCodes'), link: '/promo-codes' }
          ] : []),
          { label: t('reports'), link: '/reports' },
          ...(user.role === 'owner' ? [{ label: t('systemConfig'), link: '/system-config' }] : [])
        ]
      }
    ] : [])
  ];

  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard">
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink"></i>
        </div>
        <div className="sidebar-brand-text mx-3">ROK TOOL</div>
      </Link>

      <hr className="sidebar-divider my-0" />

      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          icon={item.icon}
          label={item.label}
          items={item.items}
          isActive={activeMenu === index}
          onClick={() => setActiveMenu(activeMenu === index ? null : index)}
        />
      ))}
    </ul>
  );
};

export default Menu;