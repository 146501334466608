import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const UploadStats = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { serverId } = useParams();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null);
    setSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError(t('Please select a file'));
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(false);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/rok-stats/${serverId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
      });

      if (response.data.success) {
        setSuccess(true);
        setFile(null);
      } else {
        setError(t('File upload failed: ') + (response.data.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setError(t('Error uploading file: ') + (error.response?.data?.error || error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <h1>{t('uploadStats')}</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fileInput">{t('Choose file')}</label>
          <input 
            type="file" 
            className="form-control-file" 
            id="fileInput" 
            onChange={handleFileChange} 
            accept=".csv"
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? t('Uploading...') : t('Upload')}
        </button>
      </form>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
      {success && <div className="alert alert-success mt-3">{t('File uploaded successfully')}</div>}
    </div>
  );
};

export default UploadStats;