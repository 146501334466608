// client/src/components/UserList.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Table, Form, InputGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UserList = () => {
  const { t } = useTranslation();
  const { serverId } = useParams();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/users`, {
          withCredentials: true
        });
        if (Array.isArray(response.data)) {
          setUsers(response.data);
          setFilteredUsers(response.data);
        } else {
          throw new Error('Received data is not an array');
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch users: ' + err.message);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [serverId]);

  useEffect(() => {
    if (Array.isArray(users)) {
      const results = users.filter(user =>
        user.governor_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.governor_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.discord_name && user.discord_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (user.discord_id && user.discord_id.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredUsers(results);
    }
  }, [searchTerm, users]);

  if (loading) return <div>{t('loading')}</div>;
  if (error) return <div>{t('error')}: {error}</div>;

  return (
    <Container>
      <h1>{t('userList')}</h1>
      <Form className="mb-3">
        <InputGroup>
          <Form.Control
            type="text"
            placeholder={t('searchUsers')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button variant="outline-secondary" onClick={() => setSearchTerm('')}>
            {t('clear')}
          </Button>
        </InputGroup>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('governorId')}</th>
            <th>{t('governorName')}</th>
            <th>{t('discordName')}</th>
            <th>{t('discordId')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredUsers) && filteredUsers.map((user) => (
            <tr key={user.governor_id}>
              <td>{user.governor_id}</td>
              <td>{user.governor_name}</td>
              <td>{user.discord_name || t('notLinked')}</td>
              <td>{user.discord_id || t('notLinked')}</td>
              <td>
                <Link to={`/user-stats/${user.governor_id}?serverId=${serverId}`} className="btn btn-primary btn-sm">
                  {t('viewStats')}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default UserList;