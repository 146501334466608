import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const KvKDailyStats = ({ kvkId, startDate, endDate }) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serverId = searchParams.get('serverId');

  useEffect(() => {
    const fetchStats = async () => {
      if (!serverId) {
        setError('Server ID is missing');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/${kvkId}/stats`, {
          params: { startDate, endDate, serverId },
          withCredentials: true
        });
        setStats(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch daily KvK stats');
        setLoading(false);
      }
    };
    fetchStats();
  }, [kvkId, startDate, endDate, serverId]);

  if (loading) return <div>Loading daily stats...</div>;
  if (error) return <div>Error: {error}</div>;


  return (
    <div className="kvk-daily-stats">
      <h2>KvK Daily Statistics</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={stats}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total_power" fill="#8884d8" name="Power" />
          <Bar dataKey="total_kill_points" fill="#82ca9d" name="Kill Points" />
          <Bar dataKey="total_deaths" fill="#ffc658" name="Deaths" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default KvKDailyStats;