import React from 'react';
import { useTranslation } from 'react-i18next';


function Login() {
  const { t } = useTranslation(['login', 'translation']);
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/discord`;
  };

  return (
    <div className="text-center">
      <h1 className="h3 mb-0 text-gray-800">{t('login:loginText')}</h1>
      <p className="mb-4">{t('login:loginTextDiscord')}</p>
      <button onClick={handleLogin} className="btn btn-primary btn-icon-split">
        <span className="icon text-white-50">
          <i className="fab fa-discord"></i>
        </span>
        <span className="text">{t('login:loginBtnTextDiscord')}</span>
      </button>
    </div>
  );
}

export default Login;