import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ServerPermissionsConfig = () => {
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState('');
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

const fetchOwnedServers = useCallback(async () => {
  try {
    setLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/owned-servers`, { withCredentials: true });
    console.log('Owned servers response:', response.data);
    if (Array.isArray(response.data)) {
      setServers(response.data);
      if (response.data.length > 0) {
        setSelectedServer(response.data[0].server_id);
      }
    } else {
      console.error('Owned servers data is not an array:', response.data);
      setServers([]);
    }
  } catch (error) {
    console.error('Error fetching owned servers:', error);
    setError('Failed to fetch owned servers');
    setServers([]);
  } finally {
    setLoading(false);
  }
}, []);

const fetchRolesAndPermissions = useCallback(async () => {
  if (!selectedServer) {
    console.log('No server selected, skipping fetch');
    return;
  }
  
  setLoading(true);
  setError(null);
  try {
    console.log('Fetching roles and permissions for server:', selectedServer);
    const [rolesRes, permissionsRes] = await Promise.all([
      axios.get(`${process.env.REACT_APP_API_URL}/server/${selectedServer}/roles`, { withCredentials: true }),
      axios.get(`${process.env.REACT_APP_API_URL}/server/${selectedServer}/permissions`, { withCredentials: true })
    ]);
    console.log('Roles response:', rolesRes.data);
    console.log('Permissions response:', permissionsRes.data);
    
    if (Array.isArray(rolesRes.data)) {
      setRoles(rolesRes.data);
    } else {
      console.error('Roles data is not an array:', rolesRes.data);
      setRoles([]);
    }
    
    const permissionsObj = Array.isArray(permissionsRes.data) 
      ? permissionsRes.data.reduce((acc, perm) => {
          if (!acc[perm.discord_role_id]) {
            acc[perm.discord_role_id] = {};
          }
          acc[perm.discord_role_id][perm.permission_type] = true;
          return acc;
        }, {})
      : {};
    
    console.log('Transformed permissions:', permissionsObj);
    setPermissions(permissionsObj);
  } catch (err) {
    console.error('Error fetching roles and permissions:', err);
    setError('Failed to fetch roles and permissions: ' + err.message);
    setRoles([]);
    setPermissions({});
  } finally {
    setLoading(false);
  }
}, [selectedServer]);

  useEffect(() => {
    fetchOwnedServers();
  }, [fetchOwnedServers]);

useEffect(() => {
  if (selectedServer) {
    console.log('Selected server changed:', selectedServer);
    fetchRolesAndPermissions();
  }
}, [selectedServer, fetchRolesAndPermissions]);

  const handleServerChange = (e) => {
    const newServer = e.target.value;
    setSelectedServer(newServer);
    navigate(`/server/${newServer}/permissions`);
  };

  const handlePermissionChange = async (roleId, permissionType, isChecked) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/server/${selectedServer}/permissions`, {
        roleId,
        permissionType,
        hasPermission: isChecked
      }, { withCredentials: true });
      setPermissions(prev => ({
        ...prev,
        [roleId]: {
          ...prev[roleId],
          [permissionType]: isChecked
        }
      }));
    } catch (err) {
      setError('Failed to update permission: ' + err.message);
    }
  };

  const syncDiscordRoles = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/server/${selectedServer}/sync-roles`, {}, { withCredentials: true });
      fetchRolesAndPermissions();
    } catch (err) {
      setError('Failed to sync Discord roles: ' + err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
return (
  <div>
    <h2>Server Permissions Configuration</h2>
    <select value={selectedServer} onChange={handleServerChange}>
      <option value="">Select a server</option>
      {servers.map((server) => (
        <option key={server.server_id} value={server.server_id}>
          {server.server_name}
        </option>
      ))}
    </select>
    {loading && <div>Loading...</div>}
    {error && <div>Error: {error}</div>}
    {!loading && !error && selectedServer && (
      <>
        <button onClick={syncDiscordRoles}>Sync Discord Roles</button>
        {roles.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Role</th>
                <th>View Verifications</th>
                <th>Extend Subscription</th>
                <th>Access Server Verification</th>
              </tr>
            </thead>
            <tbody>
              {roles.map(role => (
                <tr key={role.id}>
                  <td>{role.role_name}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[role.role_id]?.viewVerifications || false}
                      onChange={(e) => handlePermissionChange(role.role_id, 'viewVerifications', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[role.role_id]?.extendSubscription || false}
                      onChange={(e) => handlePermissionChange(role.role_id, 'extendSubscription', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[role.role_id]?.accessServerVerification || false}
                      onChange={(e) => handlePermissionChange(role.role_id, 'accessServerVerification', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[role.role_id]?.uploadStats || false}
                      onChange={(e) => handlePermissionChange(role.role_id, 'uploadStats', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[role.role_id]?.viewStats || false}
                      onChange={(e) => handlePermissionChange(role.role_id, 'viewStats', e.target.checked)}
                    />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No roles found for this server.</div>
        )}
      </>
    )}
  </div>
);
};
export default ServerPermissionsConfig;