import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Table, Tabs, Tab } from 'react-bootstrap';

const EventManagement = ({ serverId }) => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({ name: '', description: '', startTime: '', eventType: 'server', kvkId: '' });
  const [activeTab, setActiveTab] = useState('server');
  const [activeKvKs, setActiveKvKs] = useState([]);

useEffect(() => {
  fetchEvents();
  if (activeTab === 'kvk') {
    fetchActiveAndUpcomingKvKs();
  }
}, [serverId, activeTab]);


  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/events`, {
        params: { eventType: activeTab },
        withCredentials: true
      });
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

const fetchActiveAndUpcomingKvKs = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/active-kvks`, { withCredentials: true });
    setActiveKvKs(response.data);
  } catch (error) {
    console.error('Error fetching active and upcoming KvKs:', error);
  }
};
  const handleInputChange = (e) => {
    setNewEvent({ ...newEvent, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/server/${serverId}/events`, newEvent, { withCredentials: true });
      setNewEvent({ name: '', description: '', startTime: '', eventType: activeTab, kvkId: '' });
      fetchEvents();
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setNewEvent({ ...newEvent, eventType: tab, kvkId: '' });
  };

  return (
    <div>
      <h2>Event Management</h2>
      <Tabs activeKey={activeTab} onSelect={handleTabChange}>
        <Tab eventKey="server" title="Server Events">
          <EventForm
            newEvent={newEvent}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            eventType="server"
          />
          <EventList events={events} />
        </Tab>
        <Tab eventKey="kvk" title="KvK Events">
          <EventForm
            newEvent={newEvent}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            eventType="kvk"
            activeKvKs={activeKvKs}
          />
          <EventList events={events} />
        </Tab>
      </Tabs>
    </div>
  );
};

const EventForm = ({ newEvent, handleInputChange, handleSubmit, eventType, activeKvKs }) => (
  <Form onSubmit={handleSubmit}>
    <Form.Group>
      <Form.Label>Event Name</Form.Label>
      <Form.Control type="text" name="name" value={newEvent.name} onChange={handleInputChange} required />
    </Form.Group>
    <Form.Group>
      <Form.Label>Description</Form.Label>
      <Form.Control as="textarea" name="description" value={newEvent.description} onChange={handleInputChange} />
    </Form.Group>
    <Form.Group>
      <Form.Label>Start Time</Form.Label>
      <Form.Control type="datetime-local" name="startTime" value={newEvent.startTime} onChange={handleInputChange} required />
    </Form.Group>
    <Form.Control type="hidden" name="eventType" value={eventType} />
    {eventType === 'kvk' && (
      <Form.Group>
        <Form.Label>KvK (Active or Upcoming)</Form.Label>
        <Form.Control as="select" name="kvkId" value={newEvent.kvkId} onChange={handleInputChange} required>
          <option value="">Select KvK</option>
          {activeKvKs.map(kvk => (
             <option key={kvk.id} value={kvk.id}>{kvk.name} ({new Date(kvk.start_date).toLocaleDateString()} - {kvk.end_date ? new Date(kvk.end_date).toLocaleDateString() : 'Ongoing'})</option>
          ))}
        </Form.Control>
      </Form.Group>
    )}
    <Button type="submit">Create Event</Button>
  </Form>
);

const EventList = ({ events }) => (
  <Table striped bordered hover className="mt-3">
    <thead>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Start Time</th>
        {events[0] && events[0].kvk_name && <th>KvK</th>}
      </tr>
    </thead>
    <tbody>
      {events.map(event => (
        <tr key={event.id}>
          <td>{event.name}</td>
          <td>{event.description}</td>
          <td>{new Date(event.start_time).toLocaleString()}</td>
          {event.kvk_name && <td>{event.kvk_name}</td>}
        </tr>
      ))}
    </tbody>
  </Table>
);

export default EventManagement;