import React from 'react';
import { useNavigate } from 'react-router-dom';

function ExtendCancel() {
  const navigate = useNavigate();

  return (
    <div>
      <h2>Subscription Extension Cancelled</h2>
      <p>Your subscription extension has been cancelled. No changes were made to your subscription.</p>
      <button onClick={() => navigate('/manage-servers')}>Back to Manage Servers</button>
    </div>
  );
}

export default ExtendCancel;