import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DiscordInvite from './DiscordInvite';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function ManageServers() {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchServers();
  }, []);

  const fetchServers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/servers`, { withCredentials: true });
      const serversWithSubscriptions = await Promise.all(
        response.data.map(async (server) => {
          const subscriptionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/details/${server.id}`, { withCredentials: true });
          return { 
            ...server, 
            subscription: subscriptionResponse.data,
            discord_id: server.discord_server_id
          };
        })
      );
      setServers(serversWithSubscriptions);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching servers:', error);
      setError('Failed to load servers');
      setLoading(false);
    }
  };

  const extendSubscription = async (subscriptionId, serverId) => {
    try {
      console.log('Attempting to extend subscription:', subscriptionId, 'for server:', serverId);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscription/extend/${subscriptionId}`,
        { serverId },
        { withCredentials: true }
      );
      console.log('Extend subscription response:', response.data);

      if (response.data.sessionId) {
        console.log('Redirecting to Stripe checkout');
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        });

        if (error) {
          console.error('Stripe redirect error:', error);
          setError(`Payment failed: ${error.message}`);
        }
      } else {
        console.log('Subscription extended without payment');
        fetchServers();
      }
    } catch (err) {
      console.error('Error extending subscription:', err);
      setError('Failed to extend subscription: ' + (err.response?.data?.error || err.message));
    }
  };

  const cancelSubscription = async (subscriptionId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/subscription/cancel/${subscriptionId}`, {}, { withCredentials: true });
      fetchServers();
    } catch (err) {
      setError('Failed to cancel subscription');
    }
  };

  const changePlan = async (subscriptionId, newPlan) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscription/change-plan/${subscriptionId}`,
        { newPlan },
        { withCredentials: true }
      );

      if (response.data.sessionId) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        });

        if (error) {
          setError(`Payment failed: ${error.message}`);
        }
      } else {
        fetchServers();
      }
    } catch (err) {
      setError('Failed to change subscription plan');
    }
  };

  const handleConfigClick = (serverId) => {
    navigate(`/server-configuration/${serverId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Manage Servers</h1>
      <DiscordInvite />
      <table className="table">
        <thead>
          <tr>
            <th>Server Name</th>
            <th>Discord ID</th>
            <th>Subscription Plan</th>
            <th>Expiration Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {servers.map(server => (
            <tr key={server.id}>
              <td>{server.name}</td>
              <td>{server.discord_id}</td>
              <td>{server.subscription ? server.subscription.plan : 'No active subscription'}</td>
              <td>
                {server.subscription 
                  ? new Date(server.subscription.end_date).toLocaleDateString() 
                  : 'N/A'}
              </td>
              <td>
                {server.subscription ? (
                  <>
                    <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => cancelSubscription(server.subscription.id)}
                    >
                      Cancel Subscription
                    </button>
                    <select 
                      className="form-control form-control-sm ml-2" 
                      onChange={(e) => changePlan(server.subscription.id, e.target.value)}
                      value={server.subscription.plan}
                    >
                      <option value="Basic">Basic</option>
                      <option value="Pro">Pro</option>
                      <option value="Enterprise">Enterprise</option>
                    </select>
                    <button 
                      className="btn btn-primary btn-sm ml-2" 
                      onClick={() => extendSubscription(server.subscription.id, server.discord_id)}
                    >
                      Extend Subscription
                    </button>
                  </>
                ) : (
                  <button className="btn btn-primary btn-sm">Purchase Subscription</button>
                )}
                <button 
                  className="btn btn-info btn-sm ml-2" 
                  onClick={() => handleConfigClick(server.discord_id)}
                >
                  Configure
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ManageServers;