import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card } from 'react-bootstrap';

const KvKKillsChart = ({ stats, viewMode, selectedRange, powerRanges }) => {
  const processedStats = useMemo(() => {
    if (!stats || stats.length === 0) return [];

    // Grupuj dane wed�ug daty
    const groupedStats = stats.reduce((acc, stat) => {
      const date = stat.date.split('T')[0];
      if (!acc[date]) {
        acc[date] = { kill_points: 0, t4_kills: 0, t5_kills: 0 };
      }
      acc[date].kill_points += parseInt(stat.kill_points) || 0;
      acc[date].t4_kills += parseInt(stat.t4_kills) || 0;
      acc[date].t5_kills += parseInt(stat.t5_kills) || 0;
      return acc;
    }, {});

    // Konwertuj zgrupowane dane na tablic� i sortuj wed�ug daty
    const sortedStats = Object.entries(groupedStats)
      .map(([date, values]) => ({ date, ...values }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    const startDate = new Date(sortedStats[0].date);
    let prevDayStats = null;

    return sortedStats.map((stat, index) => {
      const currentDate = new Date(stat.date);
      const daysSinceStart = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24));

      let processedDay = {
        date: stat.date,
        daysSinceStart,
        kill_points: stat.kill_points,
        t4_kills: stat.t4_kills,
        t5_kills: stat.t5_kills
      };

      if (viewMode === 'fromStart' && index > 0) {
        processedDay.kill_points -= sortedStats[0].kill_points;
        processedDay.t4_kills -= sortedStats[0].t4_kills;
        processedDay.t5_kills -= sortedStats[0].t5_kills;
      } else if (viewMode === 'dailyChange' && prevDayStats) {
        processedDay.kill_points -= prevDayStats.kill_points;
        processedDay.t4_kills -= prevDayStats.t4_kills;
        processedDay.t5_kills -= prevDayStats.t5_kills;
      }

      prevDayStats = stat;
      return processedDay;
    });
  }, [stats, viewMode]);

  const formatYAxis = (value) => {
    if (value >= 1000000000) return `${(value / 1000000000).toFixed(1)}B`;
    if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
    if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
    return value;
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>Kills and Kill Points Chart</Card.Title>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={processedStats}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" tickFormatter={formatYAxis} />
            <YAxis yAxisId="right" orientation="right" tickFormatter={formatYAxis} />
            <Tooltip formatter={(value) => formatYAxis(value)} />
            <Legend />
            <Line yAxisId="left" type="monotone" dataKey="kill_points" stroke="#8884d8" name="Kill Points" />
            <Line yAxisId="right" type="monotone" dataKey="t4_kills" stroke="#82ca9d" name="T4 Kills" />
            <Line yAxisId="right" type="monotone" dataKey="t5_kills" stroke="#ffc658" name="T5 Kills" />
          </LineChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};

export default KvKKillsChart;