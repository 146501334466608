import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import KvKOverallStats from './KvKOverallStats';
import KvKDailyStats from './KvKDailyStats';
import KvKParticipantStats from './KvKParticipantStats';

const KvKDetails = () => {
  const { kvkId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serverId = searchParams.get('serverId');

  const [kvkDetails, setKvkDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKvKDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/${kvkId}`, {
          params: { serverId },
          withCredentials: true
        });
        setKvkDetails(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch KvK details');
        setLoading(false);
      }
    };

    if (serverId) {
      fetchKvKDetails();
    } else {
      setError('Server ID is missing');
      setLoading(false);
    }
  }, [kvkId, serverId]);

  if (loading) return <div>Loading KvK details...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="kvk-details">
      <h1>{kvkDetails.name}</h1>
      <p>Start Date: {new Date(kvkDetails.start_date).toLocaleDateString()}</p>
      <p>End Date: {kvkDetails.end_date ? new Date(kvkDetails.end_date).toLocaleDateString() : 'Ongoing'}</p>
      
      <Tabs defaultActiveKey="overall" id="kvk-stats-tabs">
        <Tab eventKey="overall" title="Overall Stats">
          <KvKOverallStats 
            kvkId={kvkId} 
            startDate={kvkDetails.start_date} 
            endDate={kvkDetails.end_date || new Date().toISOString()}
          />
        </Tab>
        <Tab eventKey="daily" title="Daily Stats">
          <KvKDailyStats 
            kvkId={kvkId} 
            startDate={kvkDetails.start_date} 
            endDate={kvkDetails.end_date || new Date().toISOString()}
          />
        </Tab>
        <Tab eventKey="participants" title="Participant Stats">
          <KvKParticipantStats 
            kvkId={kvkId} 
            startDate={kvkDetails.start_date} 
            endDate={kvkDetails.end_date || new Date().toISOString()}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default KvKDetails;