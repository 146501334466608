// W pliku client/src/components/SystemConfigPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Alert, Modal } from 'react-bootstrap';

function SystemConfigPage() {
  const [config, setConfig] = useState({
    maxSubscriptionsPerUser: 0,
    defaultTrialPeriod: 0,
    emailNotifications: false,
    paymentGateway: 'stripe',
    allowMultiServerSubscriptions: false,
    gracePeriod: 3,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/system-config`, { withCredentials: true });
      setConfig(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch system configuration');
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setConfig(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const confirmAndSave = async () => {
    setShowConfirmModal(false);
    setError('');
    setSuccessMessage('');
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/system-config`, config, { withCredentials: true });
      setSuccessMessage('System configuration updated successfully');
    } catch (err) {
      setError('Failed to update system configuration');
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Container className="mt-4">
      <h2>System Configuration</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Max Subscriptions Per User</Form.Label>
          <Form.Control
            type="number"
            name="maxSubscriptionsPerUser"
            value={config.maxSubscriptionsPerUser}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Default Trial Period (days)</Form.Label>
          <Form.Control
            type="number"
            name="defaultTrialPeriod"
            value={config.defaultTrialPeriod}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Enable Email Notifications"
            name="emailNotifications"
            checked={config.emailNotifications}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Payment Gateway</Form.Label>
          <Form.Control
            as="select"
            name="paymentGateway"
            value={config.paymentGateway}
            onChange={handleInputChange}
          >
            <option value="stripe">Stripe</option>
            <option value="paypal">PayPal</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Allow Multiple Server Subscriptions"
            name="allowMultiServerSubscriptions"
            checked={config.allowMultiServerSubscriptions}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Grace Period (days)</Form.Label>
          <Form.Control
            type="number"
            name="gracePeriod"
            value={config.gracePeriod}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Button type="submit">Save Configuration</Button>
      </Form>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to save these changes? This will affect the entire system.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmAndSave}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default SystemConfigPage;