import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

const KvKArchive = () => {
  const [kvkList, setKvKList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { serverId } = useParams();

  useEffect(() => {
    fetchKvKList();
  }, [serverId]);

  const fetchKvKList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/archive/${serverId}`, {
        withCredentials: true
      });
      setKvKList(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching KvK list:', err);
      setError('Failed to fetch KvK list: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading KvK archive...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="kvk-archive">
      <h2>KvK Archive</h2>
      <table className="kvk-list">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Total Power Increase</th>
            <th>Total Kill Points Increase</th>
            <th>Total Deaths Increase</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {kvkList.map(kvk => (
            <tr key={kvk.id}>
              <td>{kvk.name}</td>
              <td>{new Date(kvk.start_date).toLocaleDateString()}</td>
              <td>{kvk.end_date ? new Date(kvk.end_date).toLocaleDateString() : 'Ongoing'}</td>
              <td>{kvk.total_power_increase.toLocaleString()}</td>
              <td>{kvk.total_kill_points_increase.toLocaleString()}</td>
              <td>{kvk.total_deaths_increase.toLocaleString()}</td>
              <td>
                <Link to={`/kvk-details/${kvk.id}?serverId=${serverId}`} className="btn btn-primary">View Details</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default KvKArchive;