import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AuthButton({ user }) {
  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/discord`;
  };

  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, { withCredentials: true });
      navigate('/');
      window.location.reload(); // Od�wie� stron�, aby zaktualizowa� stan u�ytkownika
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (user) {
    return <button onClick={handleLogout} className="btn btn-danger">Logout</button>;
  } else {
    return <button onClick={handleLogin} className="btn btn-primary">Login with Discord</button>;
  }
}

export default AuthButton;