// client/src/components/UserStats.js
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Form, ButtonGroup, Button, Alert, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import UserStatChart from './modules/user/UserStatChart';

const UserStats = () => {
  const { governorId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const serverId = searchParams.get('serverId');
console.log('Component initialized with:', { governorId, serverId });

  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = useState(new Date());
  const [viewMode, setViewMode] = useState('absolute');
  const [canViewStats, setCanViewStats] = useState(false);
  const [canCompareUsers, setCanCompareUsers] = useState(false);
  const [comparedUsers, setComparedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    if (!serverId || !governorId) {
      setError('Server ID or Governor ID is missing');
      setLoading(false);
      return;
    }
    try {
      setLoading(true);

      // Sprawd� uprawnienia
      const permissionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/permissions-me`, {
        withCredentials: true
      });
      console.log('Permissions response:', permissionsResponse.data);
      const newCanViewStats = permissionsResponse.data.isOwner || permissionsResponse.data.permissions.viewUserStats;
      setCanViewStats(newCanViewStats);
      
      const newCanCompareUsers = permissionsResponse.data.isOwner || permissionsResponse.data.permissions.compareUsers;
      setCanCompareUsers(newCanCompareUsers);

      if (!newCanViewStats) {
        console.log('User does not have permission to view stats');
        navigate('/dashboard');
        return;
      }

      if (newCanViewStats) {
        // Pobierz statystyki u�ytkownika
        console.log('Fetching stats for governor:', governorId);
        const statsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/user-stats/${governorId}`, {
          params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
          withCredentials: true
        });
        console.log('Stats response:', statsResponse.data);

        if (Array.isArray(statsResponse.data) && statsResponse.data.length > 0) {
        setStats({ [governorId]: statsResponse.data });
        setComparedUsers([{ governor_id: governorId, governor_name: 'Current User' }]);
      } else {
        console.log('No stats data received or empty array');
        setStats({});
        }
      }

      setLoading(false);
    } catch (err) {
      console.error('Error fetching user stats:', err);
      setError('Failed to fetch user stats: ' + err.message);
      setLoading(false);
    }
  };
  fetchData();
}, [governorId, serverId, startDate, endDate, navigate]);

const handleSearch = async () => {
  if (!canCompareUsers) return;

  try {
    console.log('Searching for:', searchTerm, 'Server ID:', serverId);
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/search-users`, {
      params: { query: searchTerm },
      withCredentials: true
    });
    console.log('Search response:', response.data);
    setSearchResults(response.data);
  } catch (error) {
    console.error('Error searching users:', error);
    console.error('Error response:', error.response?.data);
    setError('Failed to search users: ' + (error.response?.data?.details || error.message));
  }
};
const addUserToComparison = (user) => {
  if (comparedUsers.length < 5 && !comparedUsers.some(u => u.governor_id === user.governor_id)) {
    setComparedUsers(prev => [...prev, user]);
    fetchUserStats(user.governor_id);
  }
};
  const removeUserFromComparison = (governorId) => {
    setComparedUsers(comparedUsers.filter(u => u.governor_id !== governorId));
    setStats(prevStats => {
      const newStats = { ...prevStats };
      delete newStats[governorId];
      return newStats;
    });
  };

const fetchUserStats = async (governorId) => {
  try {
    console.log(`Fetching stats for governor: ${governorId}`);
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/user-stats/${governorId}`, {
      params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
      withCredentials: true
    });
    console.log(`Stats response for ${governorId}:`, response.data);

    if (Array.isArray(response.data) && response.data.length > 0) {
      setStats(prevStats => ({
        ...prevStats,
        [governorId]: response.data
      }));
    } else {
      console.log(`No stats data received for ${governorId}`);
    }
  } catch (error) {
    console.error(`Error fetching stats for user ${governorId}:`, error);
    setError(`Failed to fetch stats for user ${governorId}: ` + error.message);
  }
};
  if (loading) return <div>Loading user stats...</div>;
  if (error) return <Alert variant="danger">{error}</Alert>;
  if (!canViewStats) return <Alert variant="warning">You don't have permission to view these stats.</Alert>;

  return (
    <Container fluid>
      <h2>User Statistics</h2>
      <Form.Group className="mb-3">
        <Form.Label>Start Date:</Form.Label>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>End Date:</Form.Label>
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </Form.Group>
      <ButtonGroup className="mb-3">
        <Button
          variant={viewMode === 'absolute' ? 'primary' : 'secondary'}
          onClick={() => setViewMode('absolute')}
        >
          Absolute Values
        </Button>
        <Button
          variant={viewMode === 'change' ? 'primary' : 'secondary'}
          onClick={() => setViewMode('change')}
        >
          Change from Start
        </Button>
      </ButtonGroup>

      {canCompareUsers && (
        <div className="mt-3">
          <h3>Compare Users</h3>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Search by Governor ID, Name or Discord Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button variant="outline-secondary" onClick={handleSearch}>
              Search
            </Button>
          </InputGroup>
          {searchResults.length > 0 && (
            <ul>
              {searchResults.map(user => (
                <li key={user.governor_id}>
                  {user.governor_name} ({user.governor_id})
                  <Button variant="link" onClick={() => addUserToComparison(user)}>
                    Add to comparison
                  </Button>
                </li>
              ))}
            </ul>
          )}
          <div>
            <h4>Compared Users:</h4>
            <ul>
              {comparedUsers.map(user => (
                <li key={user.governor_id}>
                  {user.governor_name} ({user.governor_id})
                  <Button variant="link" onClick={() => removeUserFromComparison(user.governor_id)}>
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <UserStatChart stats={stats} viewMode={viewMode} comparedUsers={comparedUsers} />
    </Container>
  );
};

export default UserStats;