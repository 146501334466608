import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const KvKStatBlocks = ({ stats, selectedRange }) => {
  const statBlocks = [
    { key: 'power', label: 'POWER CHANGE' },
    { key: 'kill_points', label: 'KILL POINTS' },
    { key: 'deaths', label: 'DEAD TROOPS' },
    { key: 't4_kills', label: 'T4 KILLS' },
    { key: 't5_kills', label: 'T5 KILLS' },
  ];

  const formatValue = (value) => {
    const numValue = Number(value);
    if (isNaN(numValue)) {
      console.warn(`Unexpected value type for formatting: ${typeof value}`, value);
      return '0';
    }
    const absValue = Math.abs(numValue);
    if (absValue >= 1000000000) {
      return (numValue / 1000000000).toFixed(2) + 'B';
    } else if (absValue >= 1000000) {
      return (numValue / 1000000).toFixed(2) + 'M';
    } else if (absValue >= 1000) {
      return (numValue / 1000).toFixed(1) + 'K';
    } else {
      return numValue.toFixed(0);
    }
  };

  const getValueColor = (value) => {
    const numValue = Number(value);
    if (numValue > 0) return 'text-success';
    if (numValue < 0) return 'text-danger';
    return '';
  };

  console.log('KvKStatBlocks - Received stats:', stats);

  if (!Array.isArray(stats) || stats.length === 0) {
    console.error('Invalid stats data:', stats);
    return <div>No valid data available</div>;
  }

  // Grupuj statystyki wed�ug daty
  const statsByDate = stats.reduce((acc, stat) => {
    const date = stat.date.split('T')[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(stat);
    return acc;
  }, {});

  // Znajd� ostatni dzie� z danymi
  const lastDate = Object.keys(statsByDate).sort().pop();
  const lastDayStats = statsByDate[lastDate] || [];

  // Oblicz sumy dla ostatniego dnia
  const summedStats = lastDayStats.reduce((acc, stat) => {
    statBlocks.forEach(({ key }) => {
      acc[key] = (acc[key] || 0) + Number(stat[key] || 0);
    });
    return acc;
  }, {});

  console.log('KvKStatBlocks - Summed stats:', summedStats);

  return (
    <Row className="mb-4">
      {statBlocks.map(({ key, label }) => {
        const value = summedStats[key];
        console.log(`KvKStatBlocks - ${key}:`, value);
        return (
          <Col key={key} md={2}>
            <Card>
              <Card.Body>
                <Card.Title>{label}</Card.Title>
                <Card.Text className={getValueColor(value)}>
                  {formatValue(value)}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default KvKStatBlocks;