import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function SubscriptionPurchase() {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [adminServers, setAdminServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const plansRes = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/plans`, { withCredentials: true });
        setPlans(plansRes.data);

        const serversRes = await axios.get(`${process.env.REACT_APP_API_URL}/user/admin-servers`, { withCredentials: true });
        setAdminServers(serversRes.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handlePromoCodeApply = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/apply-promo`, 
        { code: promoCode, planId: selectedPlan },
        { withCredentials: true }
      );
      setDiscountedPrice(response.data.discountedPrice);
    } catch (err) {
      setError('Invalid promo code');
    }
  };

  const handlePurchase = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/purchase`, {
        planName: selectedPlan,
        serverId: selectedServer,
        promoCode
      }, { withCredentials: true });

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });

      if (error) {
        setError(error.message);
      }
    } catch (err) {
      setError('Failed to initiate purchase');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container">
      <h2 className="mb-4">Purchase Subscription</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handlePurchase}>
        <div className="form-group">
          <label htmlFor="plan">Select Plan</label>
          <select
            className="form-control"
            id="plan"
            value={selectedPlan}
            onChange={(e) => setSelectedPlan(e.target.value)}
            required
          >
            <option value="">Choose a plan...</option>
            {plans.map(plan => (
              <option key={plan.id} value={plan.name}>{plan.name} - ${plan.price}/month</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="server">Select Server</label>
          <select
            className="form-control"
            id="server"
            value={selectedServer}
            onChange={(e) => setSelectedServer(e.target.value)}
            required
          >
            <option value="">Choose a server...</option>
            {adminServers.map(server => (
              <option key={server.server_id} value={server.server_id}>{server.server_name}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="promoCode">Promo Code</label>
          <input
            type="text"
            className="form-control"
            id="promoCode"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <button type="button" className="btn btn-secondary mt-2" onClick={handlePromoCodeApply}>
            Apply Promo Code
          </button>
        </div>
        {discountedPrice && (
          <div className="alert alert-success">
            Discounted price: ${discountedPrice}
          </div>
        )}
        <button type="submit" className="btn btn-primary" disabled={loading || !selectedPlan || !selectedServer}>
          {loading ? 'Processing...' : 'Purchase Subscription'}
        </button>
      </form>
    </div>
  );
}

export default SubscriptionPurchase;