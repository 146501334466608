import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Button, Tabs, Tab, Table } from 'react-bootstrap';
import KvKModalEvents from './KvKModal-Events';

const KvKModal = ({ show, handleClose, kvkToEdit, serverId, onSubmit }) => {
  const [activeTab, setActiveTab] = useState('general');
  const [kvkData, setKvkData] = useState({
    name: '',
    description: '',
    pre_kvk_date: '',
    start_date: '',
    end_date: '',
    power_snapshot_date: '',
    kill_points_snapshot_date: '',
    dead_troops_snapshot_date: '',
    t4_kills_snapshot_date: '',
    t5_kills_snapshot_date: '',
    requirements_type: 'kills_deaths',
    dkp_multipliers: {
      t4Kills: 10,
      t5Kills: 25,
      deaths: 40
    },
    power_requirements: []
  });

  useEffect(() => {
    if (kvkToEdit) {
      setKvkData({
        ...kvkToEdit,
        pre_kvk_date: kvkToEdit.pre_kvk_date ? new Date(kvkToEdit.pre_kvk_date).toISOString().split('T')[0] : '',
        start_date: kvkToEdit.start_date ? new Date(kvkToEdit.start_date).toISOString().split('T')[0] : '',
        end_date: kvkToEdit.end_date ? new Date(kvkToEdit.end_date).toISOString().split('T')[0] : '',
        power_snapshot_date: kvkToEdit.power_snapshot_date ? new Date(kvkToEdit.power_snapshot_date).toISOString().split('T')[0] : '',
        kill_points_snapshot_date: kvkToEdit.kill_points_snapshot_date ? new Date(kvkToEdit.kill_points_snapshot_date).toISOString().split('T')[0] : '',
        dead_troops_snapshot_date: kvkToEdit.dead_troops_snapshot_date ? new Date(kvkToEdit.dead_troops_snapshot_date).toISOString().split('T')[0] : '',
        t4_kills_snapshot_date: kvkToEdit.t4_kills_snapshot_date ? new Date(kvkToEdit.t4_kills_snapshot_date).toISOString().split('T')[0] : '',
        t5_kills_snapshot_date: kvkToEdit.t5_kills_snapshot_date ? new Date(kvkToEdit.t5_kills_snapshot_date).toISOString().split('T')[0] : '',
        dkp_multipliers: kvkToEdit.dkp_multipliers 
          ? (typeof kvkToEdit.dkp_multipliers === 'string' 
             ? JSON.parse(kvkToEdit.dkp_multipliers) 
             : kvkToEdit.dkp_multipliers)
          : {
              t4Kills: 10,
              t5Kills: 25,
              deaths: 40
            },
        power_requirements: Array.isArray(kvkToEdit.power_requirements) 
          ? kvkToEdit.power_requirements.map(req => ({
              id: req.id,
              min_power: parseInt(req.min_power),
              max_power: parseInt(req.max_power),
              required_kills: req.is_percentage ? req.required_kills_percentage : req.required_kills,
              required_deaths: req.is_percentage ? req.required_deaths_percentage : req.required_deaths,
              required_dkp: req.is_percentage ? req.required_dkp_percentage : req.required_dkp,
              required_kills_percentage: req.required_kills_percentage,
              required_deaths_percentage: req.required_deaths_percentage,
              required_dkp_percentage: req.required_dkp_percentage,
              is_percentage: req.is_percentage
            }))
          : []
      });
    }
  }, [kvkToEdit]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setKvkData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleRequirementsTypeChange = (e) => {
    const newType = e.target.value;
    setKvkData(prevData => ({
      ...prevData,
      requirements_type: newType,
      power_requirements: prevData.power_requirements.map(req => ({
        ...req,
        required_kills: newType === 'dkp' || newType === 'dkp_deaths' ? null : req.required_kills,
        required_deaths: newType === 'dkp' ? null : req.required_deaths,
        required_dkp: (newType === 'dkp' || newType === 'dkp_deaths') ? (req.required_dkp || 0) : null,
        required_kills_percentage: newType === 'dkp' || newType === 'dkp_deaths' ? null : req.required_kills_percentage,
        required_deaths_percentage: newType === 'dkp' ? null : req.required_deaths_percentage,
        required_dkp_percentage: (newType === 'dkp' || newType === 'dkp_deaths') ? (req.required_dkp_percentage || 0) : null,
      }))
    }));
  };

  const handlePowerRequirementChange = (index, field, value) => {
    setKvkData(prevData => {
      const newRequirements = [...prevData.power_requirements];
      if (newRequirements[index]) {
        if (field === 'is_percentage') {
          newRequirements[index] = { 
            ...newRequirements[index], 
            [field]: value,
            required_kills: value ? newRequirements[index].required_kills_percentage : newRequirements[index].required_kills,
            required_deaths: value ? newRequirements[index].required_deaths_percentage : newRequirements[index].required_deaths,
            required_dkp: value ? newRequirements[index].required_dkp_percentage : newRequirements[index].required_dkp
          };
        } else {
          newRequirements[index] = { 
            ...newRequirements[index], 
            [field]: value,
            [`${field}_percentage`]: value
          };
        }
      }
      return { ...prevData, power_requirements: newRequirements };
    });
  };
  const handleDKPMultiplierChange = (e) => {
    const { name, value } = e.target;
    setKvkData(prevData => ({
      ...prevData,
      dkp_multipliers: { 
        ...prevData.dkp_multipliers, 
        [name]: Number(value) 
      }
    }));
  };

  const addPowerRequirement = () => {
    setKvkData(prevData => ({
      ...prevData,
      power_requirements: [
        ...prevData.power_requirements,
        { 
          min_power: 0, 
          max_power: 0, 
          required_kills: 0, 
          required_deaths: 0, 
          required_dkp: 0,
          required_kills_percentage: 0,
          required_deaths_percentage: 0,
          required_dkp_percentage: 0,
          is_percentage: true 
        }
      ]
    }));
  };

  const removePowerRequirement = (index) => {
    setKvkData(prevData => ({
      ...prevData,
      power_requirements: prevData.power_requirements.filter((_, i) => i !== index)
    }));
  };

const handleSubmit = (e) => {
  e.preventDefault();
  
  // Formatowanie dat
  const formattedData = {
    ...kvkData,
    pre_kvk_date: kvkData.pre_kvk_date ? new Date(kvkData.pre_kvk_date).toISOString() : null,
    start_date: kvkData.start_date ? new Date(kvkData.start_date).toISOString() : null,
    end_date: kvkData.end_date ? new Date(kvkData.end_date).toISOString() : null,
    power_snapshot_date: kvkData.power_snapshot_date ? new Date(kvkData.power_snapshot_date).toISOString() : null,
    kill_points_snapshot_date: kvkData.kill_points_snapshot_date ? new Date(kvkData.kill_points_snapshot_date).toISOString() : null,
    dead_troops_snapshot_date: kvkData.dead_troops_snapshot_date ? new Date(kvkData.dead_troops_snapshot_date).toISOString() : null,
    t4_kills_snapshot_date: kvkData.t4_kills_snapshot_date ? new Date(kvkData.t4_kills_snapshot_date).toISOString() : null,
    t5_kills_snapshot_date: kvkData.t5_kills_snapshot_date ? new Date(kvkData.t5_kills_snapshot_date).toISOString() : null,
    power_requirements: kvkData.power_requirements || [], // Upewnij si�, �e to jest tablica
  };
  // Sprawdzenie, czy wszystkie wymagane daty s� ustawione
  const requiredDates = ['pre_kvk_date', 'start_date', 'power_snapshot_date', 'kill_points_snapshot_date', 'dead_troops_snapshot_date', 't4_kills_snapshot_date', 't5_kills_snapshot_date'];
  const missingDates = requiredDates.filter(date => !formattedData[date]);

  if (missingDates.length > 0) {
    alert(`The following date fields are required: ${missingDates.join(', ')}`);
    return;
  }

  onSubmit(formattedData);
};

  const handleCalculateStats = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/kvk/${kvkToEdit.id}/calculate`, null, {
        withCredentials: true
      });
      alert('Statistics calculated successfully');
    } catch (error) {
      console.error('Error calculating KvK stats:', error);
      alert('Failed to calculate statistics');
    }
  };

  const formatValue = (value, isPercentage) => {
    if (isPercentage) {
      return value ? parseFloat(value).toFixed(2) : '';
    }
    return value || '';
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{kvkToEdit ? 'Edit KvK' : 'Create New KvK'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Tab eventKey="general" title="General">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" value={kvkData.name} onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" name="description" value={kvkData.description} onChange={handleInputChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Pre-KvK Date</Form.Label>
                <Form.Control type="date" name="pre_kvk_date" value={kvkData.pre_kvk_date} onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" name="start_date" value={kvkData.start_date} onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" name="end_date" value={kvkData.end_date} onChange={handleInputChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Power Snapshot Date</Form.Label>
                <Form.Control type="date" name="power_snapshot_date" value={kvkData.power_snapshot_date} onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Kill Points Snapshot Date</Form.Label>
                <Form.Control type="date" name="kill_points_snapshot_date" value={kvkData.kill_points_snapshot_date} onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Dead Troops Snapshot Date</Form.Label>
                <Form.Control type="date" name="dead_troops_snapshot_date" value={kvkData.dead_troops_snapshot_date} onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group>
                <Form.Label>T4 Kills Snapshot Date</Form.Label>
                <Form.Control type="date" name="t4_kills_snapshot_date" value={kvkData.t4_kills_snapshot_date} onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group>
                <Form.Label>T5 Kills Snapshot Date</Form.Label>
                <Form.Control type="date" name="t5_kills_snapshot_date" value={kvkData.t5_kills_snapshot_date} onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Requirements Type</Form.Label>
                <Form.Control 
                  as="select" 
                  name="requirements_type" 
                  value={kvkData.requirements_type} 
                  onChange={handleRequirementsTypeChange}
                >
                  <option value="kills_deaths">Kills/Deaths</option>
                  <option value="dkp">DKP</option>
                  <option value="dkp_deaths">DKP + Deaths</option>
                </Form.Control>
              </Form.Group>
            </Tab>
            <Tab eventKey="events" title="Events">
            <KvKModalEvents kvkId={kvkToEdit?.id} serverId={serverId} />
            </Tab>
            <Tab eventKey="dkpSettings" title="DKP Settings">
              <Form.Group>
                <Form.Label>T4 Kills Multiplier</Form.Label>
                <Form.Control 
                  type="number" 
                  name="t4Kills" 
                  value={kvkData.dkp_multipliers?.t4Kills ?? 0} 
                  onChange={handleDKPMultiplierChange} 
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>T5 Kills Multiplier</Form.Label>
                <Form.Control 
                  type="number" 
                  name="t5Kills" 
                  value={kvkData.dkp_multipliers?.t5Kills ?? 0} 
                  onChange={handleDKPMultiplierChange} 
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Deaths Multiplier</Form.Label>
                <Form.Control 
                  type="number" 
                  name="deaths" 
                  value={kvkData.dkp_multipliers?.deaths ?? 0} 
                  onChange={handleDKPMultiplierChange} 
                />
              </Form.Group>
            </Tab>
            
            <Tab eventKey="requirements" title="Requirements">
              <Form.Group>
                <Form.Label>Requirements Type</Form.Label>
                <Form.Control 
                  as="select" 
                  name="requirements_type" 
                  value={kvkData.requirements_type} 
                  onChange={handleRequirementsTypeChange}
                >
                  <option value="kills_deaths">Kills/Deaths</option>
                  <option value="dkp">DKP</option>
                  <option value="dkp_deaths">DKP + Deaths</option>
                </Form.Control>
              </Form.Group>
              <Button onClick={addPowerRequirement} className="mb-3">Add Power Requirement</Button>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Min Power</th>
                    <th>Max Power</th>
                    {kvkData.requirements_type === 'kills_deaths' && (
                      <>
                        <th>Required Kills (%)</th>
                        <th>Required Deaths (%)</th>
                      </>
                    )}
                    {(kvkData.requirements_type === 'dkp' || kvkData.requirements_type === 'dkp_deaths') && (
                      <th>Required DKP (%)</th>
                    )}
                    {kvkData.requirements_type === 'dkp_deaths' && (
                      <th>Required Deaths (%)</th>
                    )}
                    <th>Actions</th>
                  </tr>
                </thead>
          <tbody>
            {kvkData.power_requirements.map((req, index) => (
              <tr key={index}>
                <td>
                  <Form.Control
                    type="number"
                    value={req.min_power}
                    onChange={(e) => handlePowerRequirementChange(index, 'min_power', Number(e.target.value))}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={req.max_power}
                    onChange={(e) => handlePowerRequirementChange(index, 'max_power', Number(e.target.value))}
                  />
                </td>
                {kvkData.requirements_type === 'kills_deaths' && (
                  <>
                    <td>
                      <Form.Control
                        type="number"
                        value={req.required_kills_percentage}
                        onChange={(e) => handlePowerRequirementChange(index, 'required_kills_percentage', Number(e.target.value))}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={req.required_deaths_percentage}
                        onChange={(e) => handlePowerRequirementChange(index, 'required_deaths_percentage', Number(e.target.value))}
                      />
                    </td>
                  </>
                )}
                {(kvkData.requirements_type === 'dkp' || kvkData.requirements_type === 'dkp_deaths') && (
                  <td>
                    <Form.Control
                      type="number"
                      value={req.required_dkp_percentage}
                      onChange={(e) => handlePowerRequirementChange(index, 'required_dkp_percentage', Number(e.target.value))}
                    />
                  </td>
                )}
                {kvkData.requirements_type === 'dkp_deaths' && (
                  <td>
                    <Form.Control
                      type="number"
                      value={req.required_deaths_percentage}
                      onChange={(e) => handlePowerRequirementChange(index, 'required_deaths_percentage', Number(e.target.value))}
                    />
                  </td>
                )}
                <td>
                  <Button variant="danger" onClick={() => removePowerRequirement(index)}>Remove</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Tab>
          </Tabs>
          <Button type="submit" className="mt-3">Save KvK</Button>
          {kvkToEdit && (
            <Button onClick={handleCalculateStats} className="mt-3 ml-2" variant="secondary">
              Calculate Stats
            </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default KvKModal;