import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Navigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Button, Collapse, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faChevronDown, faChevronUp, faChartLine } from '@fortawesome/free-solid-svg-icons';

const RoKAccountsList = () => {
    const { t } = useTranslation();
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasPermission, setHasPermission] = useState(true);
    const [open, setOpen] = useState({});
    const { serverId } = useParams();

    useEffect(() => {
        fetchAccounts();
    }, [serverId]);

    const fetchAccounts = async () => {
        try {
            setLoading(true);
            setError(null);
            const accountsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/server/${serverId}/rok-accounts`, { 
                withCredentials: true,
                headers: { 'Accept': 'application/json' }
            });

            if (accountsResponse.headers['content-type'].includes('application/json')) {
                if (Array.isArray(accountsResponse.data)) {
                    // Pobierz najnowsze statystyki dla ka�dego konta
                    const accountsWithStats = await Promise.all(accountsResponse.data.map(async (account) => {
                        try {
                            const statsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/rok-stats/${serverId}/latest-stats`, {
                                params: { governorId: account.rid },
                                withCredentials: true,
                                headers: { 'Accept': 'application/json' }
                            });
                            return { ...account, stats: statsResponse.data[0] || {} };
                        } catch (error) {
                            console.error(`Error fetching stats for account ${account.rid}:`, error);
                            return { ...account, stats: {} };
                        }
                    }));
                    setAccounts(accountsWithStats);
                } else {
                    console.error('Unexpected accounts data format:', accountsResponse.data);
                    setAccounts([]);
                    setError(t('Received invalid accounts data from server'));
                }
            } else {
                console.error('Received non-JSON response for accounts');
                setAccounts([]);
                setError(t('Received invalid response format for accounts'));
            }
        } catch (error) {
            console.error('Error fetching RoK accounts:', error);
            if (error.response && error.response.status === 403) {
                setHasPermission(false);
            } else {
                setError(t('Error fetching RoK accounts'));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = (id) => {
        setOpen(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    if (!hasPermission) {
        return <Navigate to="/dashboard" replace />;
    }

    if (loading) return <div>{t('loading')}</div>;
    if (error) return <div className="alert alert-danger">{error}</div>;

    return (
        <div className="container mt-4">
            <h1>{t('rokAccounts')}</h1>
            {accounts.length === 0 ? (
                <div className="alert alert-info">{t('noAccountsFound')}</div>
            ) : (
                accounts.map((account) => {
                    const stats = account.stats || {};
                    return (
                        <Card key={account.id} className="mb-3">
                            <Card.Header>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>{account.rid} - {stats.governor_name || account.governor_name || t('noData')}</div>
                                    <div>
                                        {account.verified === 1 ? (
                                            <span className="text-success">
                                                <FontAwesomeIcon icon={faCheckCircle} /> {t('verified')}
                                            </span>
                                        ) : account.verified === 0 ? (
                                            <span className="text-warning">
                                                <FontAwesomeIcon icon={faTimesCircle} /> {t('pendingVerification')}
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                <FontAwesomeIcon icon={faTimesCircle} /> {t('rejected')}
                                            </span>
                                        )}
                                        <Button
                                            variant="link"
                                            onClick={() => handleToggle(account.id)}
                                            aria-controls={`collapse-${account.id}`}
                                            aria-expanded={open[account.id] || false}
                                        >
                                            <FontAwesomeIcon icon={open[account.id] ? faChevronUp : faChevronDown} />
                                        </Button>
                                        <Link
                                            to={`/user-stats/${account.rid}?serverId=${serverId}`}
                                            className="btn btn-primary btn-sm ml-2"
                                        >
                                            <FontAwesomeIcon icon={faChartLine} /> View Stats
                                        </Link>
                                    </div>
                                </div>
                            </Card.Header>
                            <Collapse in={open[account.id]}>
                                <div>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div><strong>{t('power')}:</strong> {stats.power || t('noData')}</div>
                                                <div><strong>{t('deads')}:</strong> {stats.deads || t('noData')}</div>
                                                <div><strong>{t('alliance')}:</strong> {stats.alliance || t('noData')}</div>
                                            </div>
                                            <div className="col-md-3">
                                                <div><strong>{t('rssGathered')}:</strong> {stats.rss_gathered || t('noData')}</div>
                                                <div><strong>{t('rssAssistance')}:</strong> {stats.rss_assistance || t('noData')}</div>
                                                <div><strong>{t('helpTimes')}:</strong> {stats.help_times || t('noData')}</div>
                                            </div>
                                            <div className="col-md-3">
                                                <div><strong>{t('killPoints')}:</strong> {stats.kill_points || t('noData')}</div>
                                                <div><strong>{t('killsT5')}:</strong> {stats.tier5_kills || t('noData')}</div>
                                                <div><strong>{t('killsT4')}:</strong> {stats.tier4_kills || t('noData')}</div>
                                            </div>
                                            <div className="col-md-3">
                                                <div><strong>{t('killsT3')}:</strong> {stats.tier3_kills || t('noData')}</div>
                                                <div><strong>{t('killsT2')}:</strong> {stats.tier2_kills || t('noData')}</div>
                                                <div><strong>{t('killsT1')}:</strong> {stats.tier1_kills || t('noData')}</div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </div>
                            </Collapse>
                        </Card>
                    );
                })
            )}
        </div>
    );
};

export default RoKAccountsList;