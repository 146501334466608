import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ServerProvider } from './contexts/ServerContext';
import Layout from './components/Layout';
import Home from './components/Home';
import Login from './components/Login';
import ProfileCompletion from './components/ProfileCompletion';
import UserProfile from './components/UserProfile';
import ManageServers from './components/ManageServers';
import PromoCodeManagement from './components/PromoCodeManagement';
import ReportsPage from './components/ReportsPage';
import SystemConfigPage from './components/SystemConfigPage';
import SubscriptionPurchase from './components/SubscriptionPurchase';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import SubscriptionCancel from './components/SubscriptionCancel';
import InvoiceList from './components/InvoiceList';
import ExtendSuccess from './components/ExtendSuccess';
import ExtendCancel from './components/ExtendCancel';
import UpgradeSuccess from './components/UpgradeSuccess';
import UpgradeCancel from './components/UpgradeCancel';
import Dashboard from './components/Dashboard';
import AddRoKAccount from './components/AddRoKAccount';
import RoKAccountsList from './components/RoKAccountsList';
import RoKVerificationUpload from './components/RoKVerificationUpload';
import VerificationManagement from './components/VerificationManagement';
import SubscriptionOwnerRoute from './components/SubscriptionOwnerRoute';
import ServerPermissionsConfig from './components/ServerPermissionsConfig';
import UploadStats from './components/UploadStats';
import Stats from './components/Stats';
import StatsArchive from './components/StatsArchive';
import KvKManagement from './components/KvKManagement';
import KvKStats from './components/KvKStats';
import ServerConfiguration from './components/ServerConfiguration';
import KvKArchive from './components/KvKArchive';
import KvKDetails from './components/KvKDetails';
import UserStats from './components/UserStats';
import UserList from './components/UserList';

import PushNotificationSettings from './components/PushNotificationSettings';


function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, { withCredentials: true });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
          console.error('ServiceWorker registration failed: ', err);
        });
      });
    }




  }, []);

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  return (
    <ServerProvider>
      <Router>
        <Layout user={user}>
          <Routes>
            <Route path="/avatars/*" element={null} />
            <Route path="/" element={<Home user={user} />} />
            <Route path="/login" element={user ? <Navigate to="/dashboard" replace /> : <Login />} />
            <Route path="/profile" element={<ProfileCompletion />} />
            <Route path="/user-profile" element={<UserProfile />} />

            <Route 
              path="/promo-codes" 
              element={user && user.role === 'owner' ? <PromoCodeManagement user={user} /> : <Navigate to="/dashboard" replace />} 
            />
            <Route 
              path="/reports" 
              element={user && user.role === 'owner' ? <ReportsPage /> : <Navigate to="/dashboard" replace />} 
            />
            <Route path="/system-config" element={<SystemConfigPage />} />
            <Route path="/manage-servers" element={<ManageServers />} />
            <Route path="/invoice-list" element={<InvoiceList />} />
            <Route path="/subscription/purchase" element={<SubscriptionPurchase />} />
            <Route path="/subscription/success" element={<SubscriptionSuccess />} />
            <Route path="/subscription/cancel" element={<SubscriptionCancel />} />
            <Route path="/subscription/extend-success" element={<ExtendSuccess />} />
            <Route path="/subscription/extend-cancel" element={<ExtendCancel />} />
            <Route path="/subscription/upgrade-success" element={<UpgradeSuccess />} />
            <Route path="/user-stats/:governorId" element={<UserStats />} />
            <Route path="/subscription/upgrade-cancel" element={<UpgradeCancel />} />
            <Route path="/dashboard" element={user ? <Dashboard user={user} /> : <Navigate to="/login" replace />} />
            <Route path="/server/:serverId/add-rok-account" element={user ? <AddRoKAccount /> : <Navigate to="/login" replace />} />
            <Route path="/server/:serverId/rok-accounts" element={user ? <RoKAccountsList /> : <Navigate to="/login" replace />} />
            <Route path="/server/:serverId/rok-verification/:accountId" element={user ? <RoKVerificationUpload /> : <Navigate to="/login" replace />} />
            <Route path="/server-configuration/:serverId" element={<ServerConfiguration />} />
            <Route path="/kvk/:serverId" element={user ? <KvKManagement /> : <Navigate to="/login" />} />
            <Route path="/kvk/:kvkId/stats" element={user ? <KvKStats /> : <Navigate to="/login" />} />
            <Route path="/kvk-archive/:serverId" element={<KvKArchive />} />
            <Route path="/kvk-details/:kvkId" element={<KvKDetails />} />
            <Route 
              path="/server/:serverId/users" 
              element={
                <SubscriptionOwnerRoute>
                  <UserList />
                </SubscriptionOwnerRoute>
              } 
            />
            <Route 
              path="/server/:serverId/upload-stats" 
              element={
                <SubscriptionOwnerRoute>
                  <UploadStats />
                </SubscriptionOwnerRoute>
              } 
            />
            <Route 
              path="/push-notification-settings" 
              element={user ? <PushNotificationSettings /> : <Navigate to="/login" replace />} 
            />
            <Route 
              path="/server/:serverId/stats" 
              element={
                <SubscriptionOwnerRoute>
                  <Stats />
                </SubscriptionOwnerRoute>
              } 
            />
            <Route 
              path="/server/:serverId/stats-archive" 
              element={
                <SubscriptionOwnerRoute>
                  <StatsArchive />
                </SubscriptionOwnerRoute>
              } 
            />
            <Route 
              path="/server/:serverId/rok-accounts" 
              element={
                <SubscriptionOwnerRoute>
                  <RoKAccountsList />
                </SubscriptionOwnerRoute>
              } 
            />
            <Route 
              path="/server/:serverId/verification-management" 
              element={user && (user.role === 'admin' || user.role === 'owner') ? <VerificationManagement /> : <Navigate to="/login" replace />} 
            />
            <Route 
              path="/server/:serverId/permissions"
              element={
                <SubscriptionOwnerRoute>
                  <ServerPermissionsConfig />
                </SubscriptionOwnerRoute>
              }
            />

          </Routes>
        </Layout>
      </Router>
    </ServerProvider>
  );
}

export default App;