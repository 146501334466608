import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NotificationPreferences = ({ user }) => {
  const [preferences, setPreferences] = useState({
    email_notifications: true,
    discord_notifications: true,
    discord_private_messages: false,
    calendar_notifications: true,
    reminder_24h: true,
    reminder_1h: true,
    reminder_30min: true,
    custom_reminder_time: null
  });
  const [kvkEventPreferences, setKvkEventPreferences] = useState({
    reminder_24h: true,
    reminder_1h: true,
    reminder_30min: true,
    custom_reminder_time: null
  });
  const [calendarConnections, setCalendarConnections] = useState({
    googleCalendar: false,
    iCalendar: false
  });

  useEffect(() => {
    fetchPreferences();
    fetchCalendarConnections();
    fetchKvKEventPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
      const response = await axios.get(`/api/user-notifications/${user.id}/preferences`);
      setPreferences(response.data);
    } catch (error) {
      console.error('Error fetching notification preferences:', error);
    }
  };

  const fetchCalendarConnections = async () => {
    try {
      const response = await axios.get(`/api/user-notifications/${user.id}/calendar-connections`);
      setCalendarConnections(response.data);
    } catch (error) {
      console.error('Error fetching calendar connections:', error);
    }
  };

  const fetchKvKEventPreferences = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-notifications/${user.id}/kvk-event-preferences`);
      setKvkEventPreferences(response.data);
    } catch (error) {
      console.error('Error fetching KvK event notification preferences:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPreferences(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Sending preferences:', preferences);
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/user-notifications/${user.id}/preferences`, preferences);
      alert('Preferencje powiadomie� zosta�y zaktualizowane');
    } catch (error) {
      console.error('Error updating notification preferences:', error);
      alert('Wyst�pi� b��d podczas aktualizacji preferencji');
    }
  };

  const handleKvKEventPreferenceChange = (e) => {
    const { name, value, type, checked } = e.target;
    setKvkEventPreferences(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleKvKEventPreferencesSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/user-notifications/${user.id}/kvk-event-preferences`, kvkEventPreferences);
      alert('KvK event notification preferences updated successfully');
    } catch (error) {
      console.error('Error updating KvK event notification preferences:', error);
      alert('Failed to update KvK event notification preferences');
    }
  };

const handleGoogleCalendarConnect = () => {
  window.location.href = `${process.env.REACT_APP_API_URL}/user-notifications/connect-google-calendar`;
};
  const handleICalendarGenerate = async () => {
    try {
      const response = await axios.post(`/api/user-notifications/${user.id}/generate-ical-token`);
      setCalendarConnections(prev => ({ ...prev, iCalendar: true }));
      alert(`Tw�j link iCal: ${response.data.icalLink}`);
    } catch (error) {
      console.error('Error generating iCal token:', error);
      alert('Wyst�pi� b��d podczas generowania tokenu iCal');
    }
  };

  return (
    <div className="notification-preferences-container">
      <h2>Preferencje Powiadomie�</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-control-input"
              id="email_notifications" 
              name="email_notifications" 
              checked={preferences.email_notifications} 
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor="email_notifications">Powiadomienia e-mail</label>
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-control-input"
              id="discord_notifications" 
              name="discord_notifications" 
              checked={preferences.discord_notifications} 
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor="discord_notifications">Powiadomienia Discord</label>
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-control-input"
              id="discord_private_messages" 
              name="discord_private_messages" 
              checked={preferences.discord_private_messages} 
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor="discord_private_messages">Prywatne wiadomo�ci Discord</label>
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-control-input"
              id="calendar_notifications" 
              name="calendar_notifications" 
              checked={preferences.calendar_notifications} 
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor="calendar_notifications">Powiadomienia kalendarzowe</label>
          </div>
        </div>

        <h3>Przypomnienia o wydarzeniach</h3>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-control-input"
              id="reminder_24h" 
              name="reminder_24h" 
              checked={preferences.reminder_24h} 
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor="reminder_24h">24 godziny przed</label>
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-control-input"
              id="reminder_1h" 
              name="reminder_1h" 
              checked={preferences.reminder_1h} 
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor="reminder_1h">1 godzina przed</label>
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-control-input"
              id="reminder_30min" 
              name="reminder_30min" 
              checked={preferences.reminder_30min} 
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor="reminder_30min">30 minut przed</label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="customReminderTime">W�asny czas przypomnienia (minuty przed wydarzeniem):</label>
          <input 
            type="number" 
            className="form-control"
            id="custom_reminder_time" 
            name="custom_reminder_time" 
            value={preferences.custom_reminder_time || ''} 
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="btn btn-primary">Zapisz preferencje</button>
      </form>

      <h3 className="mt-4">KvK Event Notification Preferences</h3>
      <form onSubmit={handleKvKEventPreferencesSubmit}>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="kvk_reminder_24h"
              name="reminder_24h"
              checked={kvkEventPreferences.reminder_24h}
              onChange={handleKvKEventPreferenceChange}
            />
            <label className="custom-control-label" htmlFor="kvkReminder24h">24 hours before event</label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="kvk_reminder_1h"
              name="reminder_1h"
              checked={kvkEventPreferences.reminder_1h}
              onChange={handleKvKEventPreferenceChange}
            />
            <label className="custom-control-label" htmlFor="kvkReminder1h">1 hour before event</label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="kvk_reminder_30min"
              name="reminder_30min"
              checked={kvkEventPreferences.reminder_30min}
              onChange={handleKvKEventPreferenceChange}
            />
            <label className="custom-control-label" htmlFor="kvkReminder30min">30 minutes before event</label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="kvkCustomReminderTime">Custom reminder time (minutes before event):</label>
          <input
            type="number"
            className="form-control"
            id="kvk_custom_reminder_time"
            name="custom_reminder_time"
            value={kvkEventPreferences.custom_reminder_time || ''}
            onChange={handleKvKEventPreferenceChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Save KvK Event Preferences</button>
      </form>

      <h3 className="mt-4">Po��czenia z kalendarzami</h3>
      <div className="form-group">
        <button 
          onClick={handleGoogleCalendarConnect} 
          className="btn btn-info"
          disabled={calendarConnections.googleCalendar}
        >
          {calendarConnections.googleCalendar ? 'Po��czono z Google Calendar' : 'Po��cz z Google Calendar'}
        </button>
      </div>
      <div className="form-group">
        <button 
          onClick={handleICalendarGenerate} 
          className="btn btn-info"
          disabled={calendarConnections.iCalendar}
        >
          {calendarConnections.iCalendar ? 'Wygenerowano link iCal' : 'Wygeneruj link iCal'}
        </button>
      </div>
    </div>
  );
};

export default NotificationPreferences;