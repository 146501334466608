import React, { useState } from 'react';
import axios from 'axios';

const RoKVerificationUpload = ({ accountId }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('verificationImage', file);
    formData.append('accountId', accountId);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/rok-verification/submit`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      });
      alert('Verification image uploaded successfully!');
      setFile(null);
    } catch (error) {
      console.error('Error uploading verification image:', error);
      alert('Failed to upload verification image');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="file" onChange={handleFileChange} accept="image/*" required />
      <button type="submit">Upload Verification Image</button>
    </form>
  );
};

export default RoKVerificationUpload;