import React, { useState, useEffect } from 'react';
import axios from 'axios';

function PushNotificationSettings() {
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    checkSubscription();
  }, []);

  async function checkSubscription() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-notifications/preferences`, { withCredentials: true });
      setIsSubscribed(response.data.push_notifications);
    } catch (error) {
      console.error('Error fetching push notification status:', error);
    }
  }

  async function togglePushNotifications() {
    if (isSubscribed) {
      await unsubscribeFromPush();
    } else {
      await subscribeToPush();
    }
  }

async function subscribeToPush() {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY)
      });

      // Zamiast wysy�a� subskrypcj�, wysy�amy tylko informacj� o w��czeniu powiadomie�
      await axios.post(`${process.env.REACT_APP_API_URL}/user-notifications/push-subscription`, 
        { enable: true },
        { withCredentials: true }
      );

      setIsSubscribed(true);
      console.log('Push notifications enabled successfully');
    } catch (error) {
      console.error('Error enabling push notifications:', error);
    }
  }
}
  async function unsubscribeFromPush() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
          await subscription.unsubscribe();
          await updateSubscriptionOnServer(false);
          setIsSubscribed(false);
          console.log('Successfully unsubscribed from push notifications');
        }
      } catch (error) {
        console.error('Error unsubscribing from push notifications:', error);
      }
    }
  }

  async function updateSubscriptionOnServer(subscribed) {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/user-notifications/push-subscription`, 
        { push_notifications: subscribed },
        { withCredentials: true }
      );
    } catch (error) {
      console.error('Error updating subscription on server:', error);
    }
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Push Notifications</h6>
      </div>
      <div className="card-body">
        <p className="mb-4">Enable push notifications to receive important updates even when you're not using the app.</p>
        <button 
          className={`btn ${isSubscribed ? 'btn-danger' : 'btn-primary'}`}
          onClick={togglePushNotifications}
        >
          {isSubscribed ? 'Disable Push Notifications' : 'Enable Push Notifications'}
        </button>
      </div>
    </div>
  );
}

export default PushNotificationSettings;