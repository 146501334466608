import React, { useState, useEffect } from 'react';
import NotificationPreferences from './NotificationPreferences';
import ProfileCompletion from './ProfileCompletion';
import axios from 'axios';

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('general');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/me`, { withCredentials: true });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleConnectGoogleCalendar = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Error loading user profile. Please try again later.</div>;
  }

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">User Profile</h1>
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  <a 
                    className={`nav-link ${activeTab === 'general' ? 'active' : ''}`} 
                    href="#!" 
                    onClick={() => setActiveTab('general')}
                  >
                    General
                  </a>
                </li>
                <li className="nav-item">
                  <a 
                    className={`nav-link ${activeTab === 'notifications' ? 'active' : ''}`} 
                    href="#!" 
                    onClick={() => setActiveTab('notifications')}
                  >
                    Notifications
                  </a>
                </li>
                <li className="nav-item">
                  <a 
                    className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`} 
                    href="#!" 
                    onClick={() => setActiveTab('profile')}
                  >
                    Edit Profile
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              {activeTab === 'general' && (
                <div>
                  <h2>General Information</h2>
                  <div className="form-group">
                    <label>Username:</label>
                    <input type="text" className="form-control" value={user.username} readOnly />
                  </div>
                  <div className="form-group">
                    <label>Email:</label>
                    <input type="email" className="form-control" value={user.email} readOnly />
                  </div>
                  <div className="form-group">
                    <label>Phone Number:</label>
                    <input type="tel" className="form-control" value={user.phone_number || 'Not provided'} readOnly />
                  </div>
                  <div className="form-group">
                    <label>Country:</label>
                    <input type="text" className="form-control" value={user.country || 'Not provided'} readOnly />
                  </div>
                  <div className="form-group">
                    <label>Address:</label>
                    <textarea className="form-control" value={user.address || 'Not provided'} readOnly />
                  </div>
                  <button onClick={handleConnectGoogleCalendar} className="btn btn-primary">Connect Google Calendar</button>
                </div>
              )}
              
              {activeTab === 'notifications' && (
                <NotificationPreferences user={user} />
              )}

              {activeTab === 'profile' && (
                <ProfileCompletion user={user} onUpdate={fetchUserData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;