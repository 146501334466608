// client/src/components/modules/user/UserStatChart.js
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const UserStatChart = ({ stats, viewMode, comparedUsers }) => {
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#ff4280'];

  const processedStats = Object.entries(stats).map(([governorId, userStats]) => {
    return userStats.map((stat, index) => {
      if (viewMode === 'absolute') {
        return { ...stat, governorId };
      } else {
        const firstStat = userStats[0];
        return {
          ...stat,
          governorId,
          power: stat.power - firstStat.power,
          kill_points: stat.kill_points - firstStat.kill_points,
          deaths: stat.deaths - firstStat.deaths,
          t4_kills: stat.t4_kills - firstStat.t4_kills,
          t5_kills: stat.t5_kills - firstStat.t5_kills,
        };
      }
    });
  }).flat();

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={processedStats}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        {['power', 'kill_points', 'deaths', 't4_kills', 't5_kills'].map((stat, index) => (
          comparedUsers.map((user, userIndex) => (
            <Line
              key={`${user.governor_id}-${stat}`}
              type="monotone"
              dataKey={stat}
              data={processedStats.filter(s => s.governorId === user.governor_id)}
              name={`${user.governor_name} - ${stat}`}
              stroke={colors[userIndex % colors.length]}
            />
          ))
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default UserStatChart;