import React, { useState, useEffect } from 'react';
import axios from 'axios';

const KvKStats = ({ kvkId, serverId }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchKvKStats();
  }, [kvkId, serverId]);

  const fetchKvKStats = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/kvk/${kvkId}/stats`, {
        params: { serverId },
        withCredentials: true
      });
      setStats(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching KvK stats:', err);
      setError('Failed to fetch KvK stats: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading KvK stats...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!stats) return <div>No stats available</div>;

  return (
    <div className="kvk-stats">
      <h3>KvK Statistics</h3>
      <table className="stats-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Total Power</th>
            <th>Total Kill Points</th>
            <th>Total Dead</th>
            <th>Power Change</th>
            <th>Kill Points Change</th>
            <th>Dead Change</th>
          </tr>
        </thead>
        <tbody>
          {stats.map((day, index) => {
            const prevDay = index > 0 ? stats[index - 1] : null;
            return (
              <tr key={day.date}>
                <td>{new Date(day.date).toLocaleDateString()}</td>
                <td>{day.total_power.toLocaleString()}</td>
                <td>{day.total_kill_points.toLocaleString()}</td>
                <td>{day.total_dead.toLocaleString()}</td>
                <td>{prevDay ? (day.total_power - prevDay.total_power).toLocaleString() : 'N/A'}</td>
                <td>{prevDay ? (day.total_kill_points - prevDay.total_kill_points).toLocaleString() : 'N/A'}</td>
                <td>{prevDay ? (day.total_dead - prevDay.total_dead).toLocaleString() : 'N/A'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default KvKStats;