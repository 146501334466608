// client/src/components/SubscriptionCancel.js
import React from 'react';
import { Link } from 'react-router-dom';

function SubscriptionCancel() {
  return (
    <div className="container">
      <h2>Subscription Cancelled</h2>
      <p>Your subscription purchase has been cancelled. No charges were made.</p>
      <p>If you have any questions or concerns, please contact our support team.</p>
      <Link to="/subscription/purchase" className="btn btn-primary">Try Again</Link>
      <Link to="/dashboard" className="btn btn-secondary ml-2">Go to Dashboard</Link>
    </div>
  );
}

export default SubscriptionCancel;