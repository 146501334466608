import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function ExtendSuccess() {
  const [status, setStatus] = useState('loading');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmExtension = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (!sessionId) {
        setStatus('error');
        setErrorMessage('No session ID provided');
        return;
      }

      try {
        console.log('Sending confirmation request with sessionId:', sessionId);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/subscription/confirm-extension`,
          { sessionId },
          { 
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('Received response:', response.data);

        if (response.data.success) {
          setStatus('success');
        } else {
          setStatus('error');
          setErrorMessage(response.data.error || 'Unknown error occurred');
        }
      } catch (err) {
        console.error('Error confirming subscription extension:', err);
        setStatus('error');
        setErrorMessage(err.response?.data?.error || err.message || 'Unknown error occurred');
      }
    };

    confirmExtension();
  }, [location]);

  if (status === 'loading') return <div>Processing your subscription extension...</div>;
  
  if (status === 'error') {
    return (
      <div>
        <h2>Error Processing Subscription Extension</h2>
        <p>There was an error processing your subscription extension. Please contact support.</p>
        <p>Error details: {errorMessage}</p>
        <button onClick={() => navigate('/manage-servers')}>Back to Manage Servers</button>
      </div>
    );
  }

  return (
    <div>
      <h2>Subscription Extended Successfully!</h2>
      <p>Your subscription has been extended. Thank you for your continued support.</p>
      <button onClick={() => navigate('/manage-servers')}>Back to Manage Servers</button>
    </div>
  );
}

export default ExtendSuccess;