import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Stats = () => {
    const { t } = useTranslation();
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [governorID, setGovernorID] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(''); // Dodajemy nowy stan dla wiadomo�ci
    const { serverId } = useParams();

    useEffect(() => {
        fetchStats();
    }, [serverId]);

    const fetchStats = async (filters = {}) => {
        setLoading(true);
        setError(null);
        setMessage(''); // Resetujemy wiadomo�� przed ka�dym fetchem
        try {
            console.log('Fetching stats with URL:', `${process.env.REACT_APP_API_URL}/rok-stats/${serverId}`);
            console.log('Filters:', filters);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/rok-stats/${serverId}`, {
                params: { ...filters, startDate, endDate, governorId: governorID },
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            console.log('Response:', response);
            if (Array.isArray(response.data)) {
                setStats(response.data);
            } else if (response.data && response.data.message) {
                setStats([]);
                setMessage(response.data.message);
            } else {
                console.error('Received unexpected data:', response.data);
                setStats([]);
                setError('Received invalid data from server');
            }
        } catch (error) {
            console.error('Error fetching stats:', error);
            console.error('Error response:', error.response);
            setError(t('Error fetching stats'));
            setStats([]);
        } finally {
            setLoading(false);
        }
    };

    const handleFilter = () => {
        fetchStats();
    };

    if (loading) return <div>{t('loading')}</div>;
    if (error) return <div className="alert alert-danger">{error}</div>;

    return (
        <div className="container mt-4">
            <h1>{t('statistics')}</h1>
            <div className="filter-section mb-4">
                <div className="row">
                    <div className="col-md-3">
                        <label>{t('startDate')}</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-3">
                        <label>{t('endDate')}</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-3">
                        <label>{t('governorID')}</label>
                        <input
                            type="text"
                            className="form-control"
                            value={governorID}
                            onChange={(e) => setGovernorID(e.target.value)}
                        />
                    </div>
                    <div className="col-md-3 d-flex align-items-end">
                        <button onClick={handleFilter} className="btn btn-primary">
                            {t('filter')}
                        </button>
                    </div>
                </div>
            </div>
            {stats.length === 0 ? (
                <div className="alert alert-info">{t('No stats available')}</div>
            ) : (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t('date')}</th>
                            <th>{t('governorName')}</th>
                            <th>{t('governorID')}</th>
                            <th>{t('power')}</th>
                            <th>{t('killPoints')}</th>
                            <th>{t('deads')}</th>
                            <th>{t('tier1Kills')}</th>
                            <th>{t('tier2Kills')}</th>
                            <th>{t('tier3Kills')}</th>
                            <th>{t('tier4Kills')}</th>
                            <th>{t('tier5Kills')}</th>
                            <th>{t('rssAssistance')}</th>
                            <th>{t('rssGathered')}</th>
                            <th>{t('helpTimes')}</th>
                            <th>{t('alliance')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats.map((stat, index) => (
                            <tr key={index}>
                                <td>{stat.date}</td>
                                <td>{stat.governor_name}</td>
                                <td>{stat.governor_id}</td>
                                <td>{stat.power}</td>
                                <td>{stat.kill_points}</td>
                                <td>{stat.deads}</td>
                                <td>{stat.tier1_kills}</td>
                                <td>{stat.tier2_kills}</td>
                                <td>{stat.tier3_kills}</td>
                                <td>{stat.tier4_kills}</td>
                                <td>{stat.tier5_kills}</td>
                                <td>{stat.rss_assistance}</td>
                                <td>{stat.rss_gathered}</td>
                                <td>{stat.help_times}</td>
                                <td>{stat.alliance}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Stats;