// client/src/components/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import AuthButton from './AuthButton';
import { useTranslation } from 'react-i18next';
function Home({ user }) {
  const { t } = useTranslation(['home', 'translation']);
  return (
    <div className="landing-page">
      {/* Navigation */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand" href="/">ROK Tool by White</a>
          <div className="navbar-nav ml-auto">
            <AuthButton user={user} />
          </div>
        </div>
      </nav>
      {/* Header */}
      <header className="bg-primary text-white">
        <div className="container text-center">
          <h1>Welcome to RiseOfKingdoms Tool</h1>
          <p className="lead">{t('home:startText1')}</p>
          <Link to="/login" className="btn btn-lg btn-light">{t('home:start')}</Link>
        </div>
      </header>

      {/* Page Content */}
      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-4">{t('home:ourPlans')}</h2>
          <div className="row">
            {/* Basic Plan */}
            <div className="col-lg-4">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">{t('home:tierBasic')}</h5>
                  <h6 className="card-price text-center">$9.99<span className="period">/{t('home:month')}</span></h6>
                  <hr />
                  <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft1')}</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft2')}</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft3')}</li>
                  </ul>
                  <Link to="/login" className="btn btn-block btn-primary text-uppercase">{t('home:selectPlan')}</Link>
                </div>
              </div>
            </div>
            {/* Pro Plan */}
            <div className="col-lg-4">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">{t('home:tierPro')}</h5>
                  <h6 className="card-price text-center">$19.99<span className="period">/{t('home:month')}</span></h6>
                  <hr />
                  <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft4')}</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft5')}</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft6')}</li>
                  </ul>
                  <Link to="/login" className="btn btn-block btn-primary text-uppercase">{t('home:selectPlan')}</Link>
                </div>
              </div>
            </div>
            {/* Enterprise Plan */}
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">{t('home:tierEnterprice')}</h5>
                  <h6 className="card-price text-center">$49.99<span className="period">/{t('home:month')}</span></h6>
                  <hr />
                  <ul className="fa-ul">
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft7')}</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft8')}</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft9')}</li>
                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{t('home:ft10')}</li>
                  </ul>
                  <Link to="/login" className="btn btn-block btn-primary text-uppercase">{t('home:selectPlan')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;